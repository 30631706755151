/** @format */

import React from 'react';
import Button from 'react-bootstrap/Button';
import Header from 'components/Layout/Onboarding/Header';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { getImagePosition } from 'utils/layout';

export default function FMHub(props) {
  const text = props.t('lang_onboarding:fmhub');
  const { header_title } = text;
  const { isMobileView } = props;

  const getClassNameSection = isMobileView
    ? 'mobile-fmhubpage-section'
    : 'fmhubpage-section';

  return (
    <Container fluid className='onboarding-main-container-spacing'>
      <Header header_title={header_title} />
      <Container>
        <>
          <div className='fmhubpage'>
            <img
              src='../images/image-onboard-1.png'
              className='fmhubpage image-article-page-1'
              alt='header image'
            />
            <p className='text2r'>{text.main_para}</p>
          </div>

          {text.descriptions.map(({ description, props: prop }, key) => (
            <div key={key}>
              {isMobileView ? (
                <div
                  className={`${getClassNameSection} fmhubpage-text-bottom-spacing`}>
                  <img
                    src={`../images/image-onboard-${prop.imageId}.png`}
                    className='mobile-fmhubpage-image-spacing'
                    alt={prop.alt}
                  />

                  <p className={`text2r`}>{description}</p>
                </div>
              ) : (
                <div
                  className={`${getClassNameSection} fmhubpage-text-bottom-spacing`}>
                  {prop.imagePosition === 'left' && (
                    <img src={`../images/image-onboard-3.png`} alt={prop.alt} />
                  )}
                  <p
                    className={`text2r fmhubpage-text-${getImagePosition(
                      prop.imagePosition
                    )}-spacing`}>
                    {description}
                  </p>
                  {prop.imagePosition === 'right' && (
                    <img src={`../images/image-onboard-2.png`} alt={prop.alt} />
                  )}
                </div>
              )}
              {key === 0 && (
                <>
                  <div className='fmhubpage-desc' style={{ height: '100px' }}>
                    <p className='text2r'>{text.second_para}</p>
                  </div>
                  <div className='mobile-fmhubpage-desc'>
                    <p className='text2r'>{text.second_para}</p>
                  </div>
                </>
              )}
            </div>
          ))}

          <div className='fmhubpage-buttons-container '>
            <Link to={text.button.url}>
              <Button className={'btn btn-pink fmhubpage-button'}>
                {text.button.text}
                <FontAwesomeIcon icon={faLongArrowRight} size='lg' />
              </Button>
            </Link>
          </div>
        </>
      </Container>
    </Container>
  );
}
