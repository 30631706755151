/** @format */

import { useTranslation } from 'react-i18next';
import React from 'react';
import Header from 'components/Layout/Header';
import Footer from 'components/Layout/Footer';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function PageNotFound(props) {
  const { t } = useTranslation();
  const tprop = t(`lang_${props.flow}:${props.view}`);

  return (
    <>
      <div className='main-container'>
        <Header {...props} t={t} />
        <div className='page-not-found-container'>
          <Container>
            <Row>
              <h1>{tprop.heading.text}</h1>
              <p className='text2r '>{tprop.description.text}</p>
            </Row>

            <Row>
              <Col md={8}></Col>
              <Col md={{ span: 3, offset: 1 }} xs={0}>
                <img
                  src='images/image-404.png'
                  className='loginpage-image-1'
                  alt='registration image'
                />
              </Col>
            </Row>
          </Container>
        </div>

        <Footer {...props} t={t} />
      </div>
    </>
  );
}
