/** @format */

/*****************
 **  Root-Saga  **
 *****************/
import { all, fork } from 'redux-saga/effects';
import { brokerProfileSaga } from 'stateContainer/sagas/broker-profile-saga';
import { brokerObjectSaga } from './broker-object-saga';
import { brokerObjectsSaga } from './broker-objects-saga';
import { brokerObjectsBySlugSaga } from './broker-objects-by-slug-saga';
import { moreBrokerObjectsSaga } from './more-broker-objects-saga';
import { moreBrokerObjectsBySlugSaga } from './more-broker-objects-by-slug-saga';
import { viewingTimeSlotsSaga } from './viewing-time-slots-saga';
import { brokerProfileBySlugSaga } from './broker-profile-by-slug-saga';

const sagas = [
  brokerProfileSaga,
  brokerObjectsSaga,
  brokerObjectsBySlugSaga,
  brokerObjectSaga,
  moreBrokerObjectsSaga,
  moreBrokerObjectsBySlugSaga,
  viewingTimeSlotsSaga,
  brokerProfileBySlugSaga
];

function* rootSaga() {
  const sagaForks = sagas.map((saga) => fork(saga));
  yield all([...sagaForks]);
}

export default rootSaga;
