/** @format */

export const postcodes = [
  'Abbekås',
  'Abborträsk',
  'Abisko',
  'Adak',
  'Adelsö',
  'Agnesberg',
  'Agunnaryd',
  'Alafors',
  'Alby',
  'Alfta',
  'Alingsås',
  'Allerum',
  'Almunge',
  'Alnö',
  'Alnarp',
  'Alsen',
  'Alsterbro',
  'Alstermo',
  'Altersbruk',
  'Alunda',
  'Alvesta',
  'Alvhem',
  'Ambjörby',
  'Ambjörnarp',
  'Ammarnäs',
  'Anderslöv',
  'Anderstorp',
  'Aneby',
  'Angered',
  'Ankarsrum',
  'Ankarsvik',
  'Anneberg',
  'Annelöv',
  'Annerstad',
  'Aplared',
  'Arboga',
  'Arbrå',
  'Arholma',
  'Arild',
  'Arjeplog',
  'Arkelstorp',
  'Arlöv',
  'Arlandastad',
  'Arnäsvall',
  'Arnemark',
  'Arvidsjaur',
  'Arvika',
  'Asarum',
  'county',
  'Askim',
  'Asmundtorp',
  'Aspabruk',
  'Asperö',
  'Aspås',
  'Avesta',
  'Axmar',
  'Axvall',
  'Backaryd',
  'Backe',
  'Bagarmossen',
  'Ballingslöv',
  'Bandhagen',
  'Bankeryd',
  'Bara',
  'Barsebäck',
  'Barsele',
  'Bastuträsk',
  'Beddingestrand',
  'Bengtsfors',
  'Berga',
  'Bergby',
  'Bergeforsen',
  'Berghem',
  'Bergkvara',
  'Bergshamra',
  'Bergsjö',
  'Bergvik',
  'Bettna',
  'Billdal',
  'Billeberga',
  'Billesholm',
  'Billinge',
  'Billingsfors',
  'Bispgården',
  'Bjuråker',
  'Bjurholm',
  'Bjursås',
  'Bjuv',
  'Bjärnum',
  'Bjärred',
  'Bjärtrå',
  'Bjästa',
  'Björbo',
  'Björketorp',
  'Björkliden',
  'Björklinge',
  'Björksele',
  'Björkvik',
  'Björkö',
  'Björköby',
  'Björna',
  'Björneborg',
  'Björnlunda',
  'Blåsmark',
  'Blåviksjön',
  'Blackstad',
  'Blankaholm',
  'Blattnicksele',
  'Bleket',
  'Blentarp',
  'Blidsberg',
  'Blidö',
  'Blomstermåla',
  'Bockara',
  'Boda Glasbruk',
  'Boda Kyrkby',
  'Bodafors',
  'Boden Boden',
  'Bograngen',
  'Bohus',
  'Bohus-Björkö',
  'Bohus-Malmön',
  'Boliden',
  'Bollebygd',
  'Bollnäs',
  'Bollstabruk',
  'Bolmsö',
  'Bonässund',
  'Bor',
  'Borensberg',
  'Borgafjäll',
  'Borghamn',
  'Borgholm',
  'Borgstena',
  'Borgvik',
  'Borlänge',
  'Borrby',
  'Borås',
  'Botsmark',
  'Bottnaryd',
  'Bovallstrand',
  'Boxholm',
  'Braås',
  'Brandbergen',
  'Brandstorp',
  'Brantevik',
  'Brastad',
  'Bredared',
  'Bredaryd',
  'Bredbyn',
  'Brevens Bruk',
  'Bro',
  'Broakulla',
  'Broaryd',
  'Broby',
  'Broddetorp',
  'Brokind',
  'Bromma',
  'Bromölla',
  'Brottby',
  'Bruksvallarna',
  'Brunflo',
  'Brunskog',
  'Bruzaholm',
  'Brålanda',
  'Bräcke',
  'Bräkne-Hoby',
  'Brämhult',
  'Brännö',
  'Brösarp',
  'Bua',
  'Bullaren',
  'Bullmark',
  'Bunkeflostrand',
  'Bureå',
  'Burgsvik',
  'Burseryd',
  'Burträsk',
  'By Kyrkby',
  'Bygdeå',
  'Bygdsiljum',
  'Byske',
  'Byxelkrok',
  'Bålsta',
  'Båstad',
  'Båtskärsnäs',
  'Bäckefors',
  'Bälinge',
  'Charlottenberg',
  'Dösjebro',
  'Dala-Floda',
  'Dala-Husby',
  'Dala-Järna',
  'Dalarö',
  'Dalby',
  'Dalhem',
  'Dals Långed',
  'Dals Rostock',
  'Dalsjöfors',
  'Dalskog',
  'Dalstorp',
  'Dalum',
  'Danderyd',
  'Dannike',
  'Degeberga',
  'Degerfors',
  'Degerhamn',
  'Deje',
  'Delsbo',
  'Diö',
  'Dikanäs',
  'Dingle',
  'Djura',
  'Djurhamn',
  'Djurmo',
  'Djursholm',
  'Djurås',
  'Docksta',
  'Domsjö',
  'Donsö',
  'Dorotea',
  'Drottningholm',
  'Drottningskär',
  'Duved',
  'Dvärsätt',
  'Dyltabruk',
  'Dyrön',
  'Ed',
  'Edane',
  'Edsbro',
  'Edsbruk',
  'Edsbyn',
  'Edsele',
  'Edsvalla',
  'Ekeby',
  'Ekenässjön',
  'Ekerö',
  'Eket',
  'Ekolsund',
  'Ekshärad',
  'Eksjö',
  'Eldsberga',
  'Ellös',
  'Emmaboda',
  'Enebyberg',
  'Eneryda',
  'Enhörna',
  'Enköping',
  'Enskede',
  'Enskede Gård',
  'Enskededalen',
  'Enstaberga',
  'Enviken',
  'Enåner',
  'Erikslund',
  'Eriksmåla',
  'Eringsboda',
  'Ersmark',
  'Ervalla',
  'Eskilstuna',
  'Eslöv',
  'Everöd',
  'Fagerfjäll',
  'Fagerås',
  'Fagerhult',
  'Fagersanna',
  'Fagersta',
  'Fagervik',
  'Falkenberg',
  'Falköping',
  'Falsterbo',
  'Falun',
  'Farhult',
  'Farsta',
  'Fegen',
  'Fellingsbro',
  'Fengersfors',
  'Figeholm',
  'Filipstad',
  'Finja',
  'Finnerödja',
  'Finspång',
  'Fiskebäckskil',
  'Fjugesta',
  'Fjälkinge',
  'Fjällbacka',
  'Fjärdhundra',
  'Fjärås',
  'Flarken',
  'Flen Flen',
  'Fleninge',
  'Flisby',
  'Fliseryd',
  'Floby',
  'Floda',
  'Flyinge',
  'Folkärna',
  'Fornåsa',
  'Fors',
  'Forsa',
  'Forsbacka',
  'Forserum',
  'Forshaga',
  'Forsheda',
  'Forsvik',
  'Fotskäl',
  'Fotö',
  'Fredrika',
  'Fredriksberg',
  'Fredriksdal',
  'Frillesås',
  'Frinnaryd',
  'Fristad',
  'Fritsla',
  'Frufällan',
  'Frändefors',
  'Fränsta',
  'Fröseke',
  'Frösön',
  'Frövi',
  'Funäsdalen',
  'Furudal',
  'Furulund',
  'Furusund',
  'Furuvik',
  'Fågelfors',
  'Fågelmara',
  'Fåker',
  'Fårbo',
  'Fårö',
  'Fårösund',
  'Fällfors',
  'Färentuna',
  'Färgelanda',
  'Färila',
  'Färjestaden',
  'Färlöv',
  'Färnäs',
  'Föllinge',
  'Förslöv',
  'Gärds Köpinge',
  'Gärsnäs',
  'Gästrike-Hammarby',
  'Gävle',
  'Göta',
  'Gothenburg',
  'Götene',
  'Gagnef',
  'Gamleby',
  'Gammkil',
  'Gammelstad',
  'Gantofta',
  'Gargnäs',
  'Garpenberg',
  'Garphyttan',
  'Garsås',
  'Gemla',
  'Genarp',
  'Genevad',
  'Getinge',
  'Gideå',
  'Gimo',
  'Gislaved',
  'Glanshammar',
  'Glava',
  'Glemmingebro',
  'Glimåkra',
  'Glommen',
  'Glommersträsk',
  'Glumslöv',
  'Gnarp',
  'Gnesta',
  'Gnosjö',
  'Gotland Tofta',
  'Granbergsdal',
  'Grangärde',
  'Granö',
  'Graninge',
  'Grebbestad',
  'Grevie',
  'Grillby',
  'Grimeton',
  'Grimslöv',
  'Grimstorp',
  'Grimsås',
  'Grinda',
  'Grisslehamn',
  'Grums',
  'Grundsund',
  'Grycksbo',
  'Gryt',
  'Grytgöl',
  'Grythyttan',
  'Gråbo',
  'Gräddö',
  'Grängesberg',
  'Gränna',
  'Gränö',
  'Gräsmark',
  'Gräsmyr',
  'Grästorp',
  'Gräsö',
  'Grödinge',
  'Grönahög',
  'Grönskåra',
  'Grötö',
  'Gualöv',
  'Gudhem',
  'Guldsmedshyttan',
  'Gullabo',
  'Gullaskruv',
  'Gullbrandstorp',
  'Gullholmen',
  'Gullringen',
  'Gullspång',
  'Gunnarn',
  'Gunnarsbyn',
  'Gunnarskog',
  'Gunnebo',
  'Gunnilse',
  'Gusselby',
  'Gustafs',
  'Gustavsberg',
  'Gustavsfors',
  'Gusum',
  'Gysinge',
  'Gyttorp',
  'Gålö',
  'Gånghester',
  'Gårdsjö',
  'Gäddede',
  'Gällivare',
  'Gällnöby',
  'Gällstad',
  'Gällö',
  'Habo',
  'Hackås',
  'Hagfors',
  'Hajom',
  'Hakenäset',
  'Hakkas',
  'Hallabro',
  'Hallaryd',
  'Hallaröd',
  'Hallen',
  'Hallsberg',
  'Hallstahammar',
  'Hallstavik',
  'Halltorp',
  'Halmstad',
  'Hamburgsund',
  'Hammar',
  'Hammarstrand',
  'Hammarö',
  'Hammenhög',
  'Hammerdal',
  'Hamneda',
  'Hamra',
  'Hamrångefjärden',
  'Hanaskog',
  'Handen',
  'Haninge',
  'Haparanda',
  'Harads',
  'Harbo',
  'Harestad',
  'Hargshamn',
  'Harlösa',
  'Harmåner',
  'Harplinge',
  'Harö',
  'Hassela',
  'Hasselfors',
  'Hasslarp',
  'Hasslö',
  'Havdhem',
  'Haverdal',
  'Havstenssund',
  'Heberg',
  'Heby',
  'Hedared',
  'Hede',
  'Hedekas',
  'Hedemora',
  'Hedenäset',
  'Hedesunda',
  'Helgum',
  'Helsingborg',
  'Hemavan',
  'Hemmingsmark',
  'Hemse',
  'Henån',
  'Herrljunga',
  'Herräng',
  'Hestra',
  'Hillared',
  'Hillerstorp',
  'Hindås',
  'Hishult',
  'Hisings Backa',
  'Hisings Kärra',
  'Hjälteby',
  'Hjältevad',
  'Hjärnarp',
  'Hjärtum',
  'Hjärup',
  'Hjo',
  'Hjorted',
  'Hjortkvarn',
  'Hjortsberga',
  'Hofors',
  'Hok',
  'Holm',
  'Holmsjö',
  'Holmsund',
  'Holmsveden',
  'Holmön',
  'Holsbybrunn',
  'Holsljunga',
  'Horda',
  'Horn',
  'Horndal',
  'Hornmyr',
  'Horred',
  'Hortlax',
  'Hoting',
  'Hova',
  'Hovmantorp',
  'Hovås',
  'Huaröd',
  'Huddinge',
  'Huddungeby',
  'Hudiksvall',
  'Hult',
  'Hultafors',
  'Hultsfred',
  'Hunnebostrand',
  'Husarö',
  'Husby-Rekarne',
  'Huskvarna',
  'Husum',
  'Hyllinge',
  'Hyltebruk',
  'Hyppeln',
  'Hyssna',
  'Håcksvik',
  'Håknäs',
  'Hålanda',
  'Hållnäs',
  'Hållsta',
  'Hålta',
  'Hårsfjärden',
  'Håverud',
  'Hägersten',
  'Häggeby',
  'Häggenås',
  'Häljarp',
  'Hällaström',
  'Hällefors',
  'Hälleforsnäs',
  'Hällekis',
  'Hällestad',
  'Hällevadsholm',
  'Hälleviksstrand',
  'Hällingsjö',
  'Hällnäs',
  'Hälsö',
  'Häradsbäck',
  'Härnösand',
  'Härryda',
  'Härslöv',
  'Hässelby',
  'Hässleholm',
  'Hästveda',
  'Höganäs',
  'Högland',
  'Högsby',
  'Högsjö',
  'Högsäter',
  'Höje',
  'Hökerum',
  'Hölö',
  'Höljes',
  'Höllviken',
  'Hönö',
  'Hörby',
  'Hörja',
  'Hörnefors',
  'Höviksnäs',
  'Höör',
  'Idkerberget',
  'Idre',
  'Iggesund',
  'Ilsbo',
  'Immeln',
  'Indal',
  'Ingarö',
  'Ingatorp',
  'Ingelstad',
  'Ingmarsö',
  'Insjön',
  'Istorp',
  'Jäckvik',
  'Jädraås',
  'Jämjö',
  'Jämshög',
  'Järbo',
  'Järfälla',
  'Järlåsa',
  'Järna',
  'Järnforsen',
  'Järpen',
  'Järpås',
  'Järved',
  'Järvsö',
  'Jät',
  'Jättendal',
  'Jävrebyn',
  'Jönköping',
  'Jönåker',
  'Jörlanda',
  'Jörn',
  'Johanneshov',
  'Johannishus',
  'Jokkmokk',
  'Jonsered',
  'Jonstorp',
  'Jordbro',
  'Jukkasjärvi',
  'Julita',
  'Junosuando',
  'Junsele',
  'Juoksengi',
  'Kåbdalis',
  'Kåge',
  'Kågeröd',
  'Kållekärr',
  'Kållered',
  'Kårböle',
  'Kälarne',
  'Källby',
  'Källö-Knippla',
  'Käringön',
  'Kärna',
  'Kättilstorp',
  'Kävlinge',
  'Köping',
  'Köpingebro',
  'Köpingsvik',
  'Köpmanholmen',
  'Köpmannebro',
  'Köpstadsö',
  'Kainulasjärvi',
  'Kalix',
  'Kalix-Nyborg',
  'Kallinge',
  'Kalmar',
  'Kalv',
  'Kalvsund',
  'Kalvsvik',
  'Kalvträsk',
  'Kangos',
  'Kareby',
  'Karesuando',
  'Karl Gustav',
  'Karlholmsbruk',
  'Karlsborg',
  'Karlsborgsverken',
  'Karlshamn',
  'Karlskoga',
  'Karlskrona',
  'Karlstad',
  'Karungi',
  'Katrineholm',
  'Kattarp',
  'Katthammarsvik',
  'Kil',
  'Kilafors',
  'Killeberg',
  'Kilsmo',
  'Kimstad',
  'Kinna',
  'Kinnahult',
  'Kinnared',
  'Kinnarp',
  'Kinnarumma',
  'Kiruna',
  'Kisa',
  'Kista',
  'Kittelfjäll',
  'Kivik',
  'Klågerup',
  'Klädesholmen',
  'Klässbol',
  'Klövedal',
  'Klövsjö',
  'Klagshamn',
  'Klagstorp',
  'Klavreström',
  'Klimpfjäll',
  'Klintehamn',
  'Klippan',
  'Klockrike',
  'Knislinge',
  'Knivsta',
  'Knutby',
  'Knäred',
  'Kode',
  'Kolbäck',
  'Koler',
  'Kolmården',
  'Kolsva',
  'Konga',
  'Kopparberg',
  'Koppom',
  'Korpilombolo',
  'Korsberga',
  'Korskrogen',
  'Koskullskulle',
  'Kosta',
  'Kovland',
  'Kramfors',
  'Kristdala',
  'Kristianstad',
  'Kristineberg',
  'Kristinehamn',
  'Krokom',
  'Krylbo',
  'Kullavik',
  'Kulltorp',
  'Kumla',
  'Kungälv',
  'Kungens Kurva',
  'Kungsängen',
  'Kungsäter',
  'Kungsör',
  'Kungsbacka',
  'Kungsgården',
  'Kungshamn',
  'Kurravaara',
  'Kusmark',
  'Kvibille',
  'Kvicksund',
  'Kvidinge',
  'Kvikkjokk',
  'Kvillsfors',
  'Kvissleby',
  'Kvänum',
  'Kyrkesund',
  'Kyrkhult',
  'Långaryd',
  'Långasjö',
  'Långbäcken',
  'Långsele',
  'Långserud',
  'Långshyttan',
  'Långträsk',
  'Långviksmon',
  'Långås',
  'Läckeby',
  'Länghem',
  'Länna',
  'Lärbro',
  'Löberöd',
  'Löddeköpinge',
  'Löderup',
  'Lödöse',
  'Lögdeå',
  'Lönashult',
  'Lönneberga',
  'Lönsboda',
  'Löttorp',
  'Lövestad',
  'Lövstabruk',
  'Lövånger',
  'Lagan',
  'Laholm',
  'Laisvall',
  'Lajksjöberg',
  'Lakene',
  'Lammhult',
  'Landeryd',
  'Landsbro',
  'Landskrona',
  'Landvetter',
  'Lannavaara',
  'Lansjärv',
  'Lappträsk',
  'Larv',
  'Latikberg',
  'Lavsjön',
  'Laxå',
  'Lekeryd',
  'Leksand',
  'Lenhovda',
  'Lerberget',
  'Lerdala',
  'Lerum',
  'Lesjöfors',
  'Lessebo',
  'Liatorp',
  'Liden',
  'Lidhult',
  'Lidingö',
  'Lidköping',
  'Lidsjöberg',
  'Likenäs',
  'Lilla Edet',
  'Lillhärdal',
  'Lillkyrka',
  'Lillpite',
  'Lima',
  'Limhamn',
  'Limmared',
  'Linderöd',
  'Lindesberg',
  'Lindome',
  'Lindås',
  'Lingbo',
  'Linghed',
  'Linghem',
  'Linköping',
  'Linneryd',
  'Listerby',
  'Lit',
  'Ljugarn',
  'Ljung',
  'Ljungaverk',
  'Ljungby',
  'Ljungbyhed',
  'Ljungbyholm',
  'Ljungdalen',
  'Ljungsarp',
  'Ljungsbro',
  'Ljungskile',
  'Ljusdal',
  'Ljusfallshammar',
  'Ljusne',
  'Ljusnedal',
  'Ljusterö',
  'Ljustorp',
  'Lofsdalen',
  'Loftahammar',
  'Lomma',
  'Los',
  'Lotorp',
  'Lovikka',
  'Ludvigsborg',
  'Ludvika',
  'Lugnvik',
  'Luleå',
  'Lund',
  'Lunde',
  'Lundsbrunn',
  'Lur',
  'Lycke',
  'Lyckeby',
  'Lycksele',
  'Lyrestad',
  'Lysekil',
  'Lysvik',
  'Målerås',
  'Målilla',
  'Målsryd',
  'Månkarbo',
  'Månsarp',
  'Månstad',
  'Mårdaklev',
  'Mårdsel',
  'Märsta',
  'Märsta Arlanda',
  'Möja',
  'Möklinta',
  'Mölle',
  'Mölltorp',
  'Mölnbo',
  'Mölndal',
  'Mölnlycke',
  'Mönsterås',
  'Mörarp',
  'Mörbylånga',
  'Mörkö',
  'Mörlunda',
  'Mörrum',
  'Mörsil',
  'Malgomaj',
  'Malmö',
  'Malmö-Sturup',
  'Malmberget',
  'Malmbäck',
  'Malmköping',
  'Malung',
  'Malungsfors',
  'Malå',
  'Mangskog',
  'Mantorp',
  'Marbäck',
  'Mariannelund',
  'Mariedamm',
  'Mariefred',
  'Marieholm',
  'Marieholmsbruk',
  'Mariestad',
  'Markaryd',
  'Marmaverken',
  'Marsfjäll',
  'Marstrand',
  'Matfors',
  'Mattisudden',
  'Mattmar',
  'Mellansel',
  'Mellanström',
  'Mellbystrand',
  'Mellerud',
  'Mellösa',
  'Mjällom',
  'Mjöbäck',
  'Mjöhult',
  'Mjölby',
  'Mockfjärd',
  'Moheda',
  'Moholm',
  'Moliden',
  'Molkom',
  'Mollösund',
  'Mora',
  'Morgongåva',
  'Morjärv',
  'Morokulien',
  'Morup',
  'Moskosel',
  'Motala',
  'Mullhyttan',
  'Mullsjö',
  'Munka-Ljungby',
  'Munkedal',
  'Munkfors',
  'Munsö',
  'Muodoslompolo',
  'Murjek',
  'Muskö',
  'Myggenäs',
  'Myresjö',
  'Nås',
  'Nälden',
  'Nämdö',
  'Närkesberg',
  'Näshulta',
  'Nässjö',
  'Näsum',
  'Näsviken',
  'Näsåker',
  'Nättraby',
  'Nävekvarn',
  'Nävlinge',
  'Nödinge',
  'Nösund',
  'Nacka',
  'Nacka Strand',
  'Nattavaara',
  'Nattavaaraby',
  'Niemisel',
  'Nikkala',
  'Nissafors',
  'Nittorp',
  'Njurunda',
  'Njutånger',
  'Nol',
  'Nora',
  'Noraström',
  'Norberg',
  'Nordingrå',
  'Nordkoster',
  'Nordmaling',
  'Nordmarkshyttan',
  'Norra Sorunda',
  'Norrahammar',
  'Norrala',
  'Norrby',
  'Norrfjärden',
  'Norrfors',
  'Norrhult',
  'Norrköping',
  'Norrsundet',
  'Norrtälje',
  'Norråker',
  'Norsborg',
  'Norsholm',
  'Norsjö',
  'Nossebro',
  'Nusnäs',
  'Nybro',
  'Nybrostrand',
  'Nye',
  'Nygård',
  'Nyhammar',
  'Nyhamnsläge',
  'Nykil',
  'Nykroppa',
  'Nykvarn',
  'Nyköping',
  'Nyland',
  'Nymölla',
  'Nynäshamn',
  'Nyvång',
  'Nyåker',
  'Obbola',
  'Ockelbo',
  'Odensbacken',
  'Offerdal',
  'Olofstorp',
  'Olofström',
  'Olsfors',
  'Onsala',
  'Ormaryd',
  'Ornö',
  'Orrefors',
  'Orrviken',
  'Orsa',
  'Osby',
  'Oskarshamn',
  'Oskarström',
  'Otterbäcken',
  'Oviken',
  'Oxelösund',
  'Oxie',
  'Påarp',
  'Pålsboda',
  'Påläng',
  'Påryd',
  'Påskallavik',
  'Pajala',
  'Partille',
  'Pauliström',
  'Pello',
  'Perstorp',
  'Pilgrimstad',
  'Piteå',
  'Pixbo',
  'Porjus',
  'Prässebo',
  'Puoltikasvaara',
  'Rabbalshede',
  'Ramdala',
  'Ramkvilla',
  'Ramlösa',
  'Ramnäs',
  'Ramsberg',
  'Ramsele',
  'Ramsjö',
  'Ramvik',
  'Randijaur',
  'Ransta',
  'Ransäter',
  'Reftele',
  'Rejmyre',
  'Rengsjö',
  'Rentjärn',
  'Resö',
  'Riddarhyttan',
  'Riksgränsen',
  'Rimbo',
  'Rimforsa',
  'Ringarum',
  'Rinkaby',
  'Risbäck',
  'Risögrund',
  'Robertsfors',
  'Rockneby',
  'Roknäs',
  'Rolfstorp',
  'Romakloster',
  'Romelanda',
  'Ronneby',
  'Rosenfors',
  'Rosersberg',
  'Rossön',
  'Rosvik',
  'Rottne',
  'Rottneros',
  'Ruda',
  'Rundvik',
  'Runhällen',
  'Runmarö',
  'Rusksele',
  'Ryd',
  'Rydaholm',
  'Rydal',
  'Rydboholm',
  'Rydebäck',
  'Rydsgård',
  'Rydöbruk',
  'Ryssby',
  'Råda',
  'Råneå',
  'Rångedala',
  'Rånäs',
  'Råå',
  'Rätan',
  'Rättvik',
  'Rävlanda',
  'Röbäck',
  'Rödeby',
  'Röfors',
  'Röke',
  'Rökå',
  'Rönneshytta',
  'Rönninge',
  'Rönnäng',
  'Rörvik',
  'Rörö',
  'Röstånga',
  'Sala',
  'Salbohed',
  'Saltsjö-Boo',
  'Saltsjö-Duvnäs',
  'Saltsjöbaden',
  'Sandared',
  'Sandarne',
  'Sandhamn',
  'Sandhem',
  'Sandhult',
  'Sandsjöfors',
  'Sandviken',
  'Sandöverken',
  'Sangis',
  'Sankt Anna',
  'Sankt Ibb',
  'Sankt Olof',
  'Saxdalen',
  'Saxtorp',
  'Segeltorp',
  'Segersta',
  'Segersäng',
  'Seglora',
  'Segmon',
  'Sennan',
  'Seskarö',
  'Sexdrega',
  'Sibbhult',
  'Sidensjö',
  'Sifferbo',
  'Sigtuna',
  'Sikfors',
  'Siljansnäs',
  'Silverdalen',
  'Simlångsdalen',
  'Simrishamn',
  'Själevad',
  'Sjöbo',
  'Sjömarken',
  'Sjötofta',
  'Sjötorp',
  'Sjövik',
  'Sjulsmark',
  'Sjuntorp',
  'Skå',
  'Skåne-Tranås',
  'Skånes Fagerhult',
  'Skåpafors',
  'Skällinge',
  'Skälvik',
  'Skänninge',
  'Skärblacka',
  'Skäret',
  'Skärhamn',
  'Skärholmen',
  'Skärplinge',
  'Sköldinge',
  'Sköllersta',
  'Sköndal',
  'Skövde',
  'Skagersvik',
  'Skanör',
  'Skara',
  'Skarpnäck',
  'Skattkärr',
  'Skattungbyn',
  'Skaulo',
  'Skebobruk',
  'Skee',
  'Skelleftehamn',
  'Skellefteå',
  'Skene',
  'Skephult',
  'Skepplanda',
  'Skeppshult',
  'Skillingaryd',
  'Skillinge',
  'Skillingsfors',
  'Skinnskatteberg',
  'Skivarp',
  'Skog',
  'Skoghall',
  'Skogstorp',
  'Skogås',
  'Skokloster',
  'Skorped',
  'Skottorp',
  'Skruv',
  'Skultuna',
  'Skummeslövsstrand',
  'Skurup',
  'Skutskär',
  'Skyllberg',
  'Skyttorp',
  'Slagnäs',
  'Slöinge',
  'Slite',
  'Slottsbron',
  'Slussfors',
  'Slutarp',
  'Smedjebacken',
  'Smedstorp',
  'Smygehamn',
  'Smålandsstenar',
  'Smögen',
  'Snesudden',
  'Solberg',
  'Solberga',
  'Sollebrunn',
  'Sollefteå',
  'Sollenkroka Ö',
  'Sollentuna',
  'Sollerön',
  'Solna',
  'Sommen',
  'Sorsele',
  'Sorunda',
  'Sparreholm',
  'Sparsör',
  'Spekeröd',
  'Spånga',
  'Staffanstorp',
  'Stallarholmen',
  'Stavsjö',
  'Stavsnäs',
  'Stavsudda',
  'Stehag',
  'Stenhamra',
  'Steninge',
  'Steningehöjden',
  'Stenkullen',
  'Stensele',
  'Stensjön',
  'Stenstorp',
  'Stenungsund',
  'Stigen',
  'Stigtomta',
  'Stillingsön',
  'Stjärnhov',
  'Stjärnsund',
  'Stoby',
  'Stocka',
  'Stockamöllan',
  'Stockaryd',
  'Stockholm',
  'Stockholm-Arlanda',
  'Stockholm-Globen',
  'Stocksund',
  'Stora Blåsjön',
  'Stora Höga',
  'Stora Levene',
  'Stora Mellösa',
  'Stora Skedvi',
  'Stora Sundby',
  'Stora Vika',
  'Storebro',
  'Storfors',
  'Storlien',
  'Storseleby',
  'Storsjö Kapell',
  'Storsund',
  'Storuman',
  'Storvik',
  'Storvreta',
  'Storå',
  'Stråssa',
  'Stråtjära',
  'Stråvalla',
  'Strängnäs',
  'Strömsbergs Bruk',
  'Strömsbruk',
  'Strömsholm',
  'Strömsnäsbruk',
  'Strömstad',
  'Strömsund',
  'Strövelstorp',
  'Strandbaden',
  'Stugun',
  'Sturefors',
  'Sturkö',
  'Styrsö',
  'Stånga',
  'Ställdalen',
  'Stöde',
  'Stöllet',
  'Sundborn',
  'Sundbyberg',
  'Sundsbruk',
  'Sundsvall',
  'Sunhultsbrunn',
  'Sunnansjö',
  'Sunne',
  'Sunnemo',
  'Surahammar',
  'Surte',
  'Svängsta',
  'Svärdsjö',
  'Svärtinge',
  'Svalöv',
  'Svanavattnet',
  'Svaneholm',
  'Svanesund',
  'Svanskog',
  'Svappavaara',
  'Svartsjö',
  'Svartå',
  'Svedala',
  'Sveg',
  'Svenljunga',
  'Svensbyn',
  'Svenshögen',
  'Svenstavik',
  'Sydkoster',
  'Sysslebäck',
  'Sågmyra',
  'Såtenäs',
  'Säffle',
  'Sälen',
  'Särna',
  'Särö',
  'Säter',
  'Sätila',
  'Sävar',
  'Säve',
  'Sävedalen',
  'Sävsjö',
  'Sävsjöström',
  'Söderala',
  'Söderby',
  'Söderbärke',
  'Söderfors',
  'Söderhamn',
  'Söderköping',
  'Södertälje',
  'Söderåkra',
  'Södra Sandby',
  'Södra Sunderbyn',
  'Södra Vi',
  'Sölvesborg',
  'Sörberge',
  'Sörbygden',
  'Sörsjön',
  'Söråker',
  'Sösdala',
  'Sövde',
  'Taberg',
  'Tallåsen',
  'Tandsbyn',
  'Tanumshede',
  'Tavelsjö',
  'Teckomatorp',
  'Tenhult',
  'Tibro',
  'Tidaholm',
  'Tidan',
  'Tierp',
  'Timmele',
  'Timmernabben',
  'Timmersdala',
  'Timrå',
  'Tingsryd',
  'Tingstäde',
  'Tived',
  'Tjällmo',
  'Tjörnarp',
  'Tjureda',
  'Tjurkö',
  'Tobo',
  'Tolg',
  'Tollarp',
  'Tollered',
  'Tomelilla',
  'Tommarp',
  'Tomteboda',
  'Torekov',
  'Torestorp',
  'Torhamn',
  'Tormestorp',
  'Torna-Hällestad',
  'Torpsbruk',
  'Torpshammar',
  'Torsby',
  'Torshälla',
  'Torslanda',
  'Torsåker',
  'Torsås',
  'Torsö',
  'Torup',
  'Tostared',
  'Totebo',
  'Trångsund',
  'Trångsviken',
  'Tråvad',
  'Trädet',
  'Träslövsläge',
  'Trönödal',
  'Tranemo',
  'Transtrand',
  'Tranås',
  'Traryd',
  'Trehörningsjö',
  'Trekanten',
  'Trelleborg',
  'Trensum',
  'Trollhättan',
  'Trosa',
  'Tullinge',
  'Tumba',
  'Tun',
  'Tungelsta',
  'Tving',
  'Tvååker',
  'Tvärred',
  'Tvärålund',
  'Tygelsjö',
  'Tyresö',
  'Tyringe',
  'Tystberga',
  'Tågarp',
  'Täby',
  'Täfteå',
  'Tällberg',
  'Tänndalen',
  'Tännäs',
  'Tärendö',
  'Tärnaby',
  'Tärnsjö',
  'Tävelsås',
  'Töcksfors',
  'Töllsjö',
  'Töre',
  'Töreboda',
  'Tösse',
  'Ucklum',
  'Uddebo',
  'Uddeholm',
  'Uddevalla',
  'Ugglarp',
  'Ullared',
  'Ullatti',
  'Ullånger',
  'Ulricehamn',
  'Ulrika',
  'Ulvöhamn',
  'Umeå',
  'Undenäs',
  'Undersåker',
  'Undrom',
  'Unnaryd',
  'Upphärad',
  'Upplands Väsby',
  'Uppsala',
  'Urshult',
  'Ursviken',
  'Uråsa',
  'Utansjö',
  'Uttran',
  'Utö',
  'Vadstena',
  'Vaggeryd',
  'Vagnhärad',
  'Valbo',
  'Valdemarsvik',
  'Valla',
  'Vallberga',
  'Vallda',
  'Vallentuna',
  'Vallhamn',
  'Vallsta',
  'Vallvik',
  'Vallåkra',
  'Valsjöbyn',
  'Valskog',
  'Vankiva',
  'Vansbro',
  'Vara',
  'Varberg',
  'Varekil',
  'Vargön',
  'Varnhem',
  'Vartofta',
  'Vassmolösa',
  'Vattholma',
  'Vaxholm',
  'Veberöd',
  'Veddige',
  'Vederslöv',
  'Vedevåg',
  'Vedum',
  'Vega',
  'Vegby',
  'Veinge',
  'Vejbystrand',
  'Vellinge',
  'Vemdalen',
  'Vemhån',
  'Vena',
  'Vendelsö',
  'Venjan',
  'Vessigebro',
  'Vetlanda',
  'Vettasjärvi',
  'Vidsel',
  'Vikarbyn',
  'Vikbolandet',
  'Viken',
  'Vikingstad',
  'Vikmanshyttan',
  'Viksjö',
  'Viksjöfors',
  'Vilhelmina',
  'Villands Vånga',
  'Vilshult',
  'Vimmerby',
  'Vinberg',
  'Vindelgransele',
  'Vindeln',
  'Vingåker',
  'Vinninga',
  'Vinslöv',
  'Vintrie',
  'Vintrosa',
  'Virestad',
  'Virsbo',
  'Virserum',
  'Visby',
  'Visingsö',
  'Viskafors',
  'Vislanda',
  'Vissefjärda',
  'Visseltofta',
  'Vistträsk',
  'Vitaby',
  'Vittangi',
  'Vittaryd',
  'Vittinge',
  'Vittsjö',
  'Vittskövle',
  'Vitå',
  'Vollsjö',
  'Vormsele',
  'Voxnabruk',
  'Vrena',
  'Vreta Kloster',
  'Vretstorp',
  'Vrigstad',
  'Vrångö',
  'Vuollerim',
  'Vålberg',
  'Vålådalen',
  'Våmhus',
  'Vårby',
  'Vårgårda',
  'Våxtorp',
  'Väckelsång',
  'Väddö',
  'Väderstad',
  'Väja',
  'Vällingby',
  'Vänersborg',
  'Vänersnäs',
  'Vänge',
  'Vännäs',
  'Vännäsby',
  'Värends Nöbbele',
  'Väring',
  'Värmdö',
  'Värmlands Nysäter',
  'Värmskog',
  'Värnamo',
  'Värsås',
  'Väröbacka',
  'Väse',
  'Västerfärnebo',
  'Västerhaninge',
  'Västerlanda',
  'Västerljung',
  'Västervik',
  'Västerås',
  'Västra Frölunda',
  'Västra Karup',
  'Västra Näshulta',
  'Västra Torup',
  'Västra Tunhem',
  'Västra Ämtervik',
  'Vätö',
  'Växjö',
  'Ydre',
  'Yngsjö',
  'Ystad',
  'Ytterby',
  'Ytterhogdal',
  'Yxlan',
  'Zinkgruvan',
  'Åby',
  'Åhus',
  'Åkarp',
  'Åkers Styckebruk',
  'Åkersberga',
  'Ålberga',
  'Åled',
  'Ålem',
  'Åmmeberg',
  'Åmotfors',
  'Åmotsbruk',
  'Åmsele',
  'Åmål',
  'Ånäset',
  'Ånge',
  'Ånimskog',
  'Åre',
  'Årjäng',
  'Årsta',
  'Årsta Havsbad',
  'Årsunda',
  'Åryd',
  'Ås',
  'Åsa',
  'Åsarna',
  'Åsarp',
  'Åsbro',
  'Åseda',
  'Åsele',
  'Åsenhöga',
  'Åsensbruk',
  'Åshammar',
  'Åskloster',
  'Åsljunga',
  'Åstol',
  'Åstorp',
  'Åtorp',
  'Åtvidaberg',
  'Älandsbro',
  'Älekulla',
  'Älgarås',
  'Älghult',
  'Älmeboda',
  'Älmestad',
  'Älmhult',
  'Älta',
  'Älvdalen',
  'Älvkarleby',
  'Älvsbyn',
  'Älvsered',
  'Älvsjö',
  'Älvängen',
  'Äng',
  'Ängelholm',
  'Ängelsberg',
  'Äppelbo',
  'Ärla',
  'Äspered',
  'Äsperöd',
  'Ätran',
  'Öckerö',
  'Ödåkra',
  'Ödåkra-Väla',
  'Ödeborg',
  'Ödeshög',
  'Ödskölt',
  'Ödsmål',
  'Öje',
  'Öjebyn',
  'Öjersjö',
  'Ölsremma',
  'Önnestad',
  'Ör',
  'Örby',
  'Örbyhus',
  'Örebro',
  'Öregrund',
  'Örkelljunga',
  'Örnsköldsvik',
  'Örsjö',
  'Örsundsbro',
  'Örträsk',
  'Örviken',
  'Ösmo',
  'Östansjö',
  'Östavall',
  'Österbybruk',
  'Österfärnebo',
  'Österhaninge',
  'Österskär',
  'Östersund',
  'Östervåla',
  'Östhammar',
  'Östmark',
  'Östra Frölunda',
  'Östra Karup',
  'Östra Ljungby',
  'Östra Ryd',
  'Östra Sönnarslöv',
  'Östra Ämtervik',
  'Överhörnäs',
  'Överkalix',
  'Överlida',
  'Övertorneå',
  'Överturingen',
  'Överum',
  'Övre Soppero',
  'Öxabäck',
  'Öxnevalla'
];
