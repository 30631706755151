/** @format */

import React from 'react';

export default function Partners(props) {
  const { tprop } = props;

  return (
    <div className='homepage-partner-container'>
      {/* regular screens */}
      <div className='image-partners-monochrome partner-content'>
        <h3>{tprop.heading.text}</h3>
      </div>

      {/* mobile screens*/}
      <div className='image-partners-monochrome-mobile partner-content'>
        <h3>{tprop.heading.text}</h3>
      </div>
    </div>
  );
}
