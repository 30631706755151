/** @format */

import React from 'react';
import { Field, ErrorMessage } from 'formik';

export default function FormField(props) {
  const {
    name,
    type,
    maxLength,
    placeholder,
    className,
    labeltext,
    labelstyle,
    errorstyle
  } = props;

  const labelStyles = labelstyle ? labelstyle : '';

  const errorStyles = errorstyle ? errorstyle : '';

  const classNames = className ? className : '';

  const length = maxLength ? maxLength : 100;

  const placeholderText = placeholder ? placeholder : '';

  return (
    <>
      {labeltext !== undefined && (
        <label htmlFor={name} className={`form-label  ${labelStyles}`}>
          {labeltext}
        </label>
      )}
      <ErrorMessage
        name={name}
        component='span'
        className={`form-error ${errorStyles}`}
      />
      <Field
        className={`form-control ${classNames}`}
        type={type ? type : 'text'}
        name={name}
        maxLength={length}
        placeholder={placeholderText}
        {...props}
      />
    </>
  );
}
