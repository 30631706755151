/** @format */

/********************
 **  Root-Reducer  **
 ********************/

import { combineReducers } from 'redux';
import { history } from 'stateContainer/history';
import { connectRouter } from 'connected-react-router';
import { stepperReducer } from './stepper-reducer';
import { onboardingReducer } from 'stateContainer/reducers/onboarding-reducer';
import { brokerProfileReducer } from './broker-profile-reducer';
import { brokerObjectsReducer } from './broker-objects-reducer';
import { brokerObjectReducer } from './broker-object-reducer';
import { viewingTimeSlotsReducer } from './viewing-time-slots-reducer';

const appReducer = combineReducers({
  router: connectRouter(history),
  stepper: stepperReducer,
  onboarding: onboardingReducer,
  brokerProfile: brokerProfileReducer,
  brokerObjects: brokerObjectsReducer,
  brokerObject: brokerObjectReducer,
  viewingTimeSlots: viewingTimeSlotsReducer
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
