/** @format */

import React from 'react';
import { Link } from 'react-router-dom';
import CustomButton from 'components/CustomButton';

export default function PostBlock(props) {
  const { buttons, heading, subheading, description, imageClassNames } = props;

  const isH1Heading =
    heading &&
    heading.props &&
    heading.props.headingType &&
    heading.props.headingType === 'h1';

  const isH3Heading = heading && !isH1Heading;

  const getIsBtnPink = (item) => {
    return item.props && item.props.color && item.props.color === 'pink';
  };

  return (
    <div className='post-block-container'>
      <div className='post-block-left'>
        <div className='heading-container'>
          {isH1Heading && <h1>{heading.text}</h1>}

          {isH3Heading && <h3>{heading.text}</h3>}

          {subheading && <p className='text1'>{subheading.text}</p>}
        </div>
        <div className='text-container'>
          <p className='text2r'>{description.text}</p>
        </div>
        <div className='button-container'>
          {buttons &&
            buttons.map((item, key) =>
              item.url ? (
                <Link to={item.url} key={key}>
                  <CustomButton
                    text={item.text}
                    icon={!getIsBtnPink(item)}
                    isBgPink={getIsBtnPink(item)}
                  />
                </Link>
              ) : (
                <CustomButton
                  text={item.text}
                  icon={!getIsBtnPink(item)}
                  isBgPink={getIsBtnPink(item)}
                  key={key}
                />
              )
            )}
        </div>
      </div>
      <div className='post-block-right'>
        <div className={imageClassNames} />
      </div>
    </div>
  );
}
