/** @format */

import * as actionTypes from 'stateContainer/action-types';

const initialState = {
  profilePicture: []
};

export const onboardingReducer = (state = initialState, action) => {
  if (action.type === actionTypes.SAVE_PROFILE_PICTURE) {
    return { ...state, profilePicture: action.payload };
  } else {
    return state;
  }
};
