/** @format */

import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import config from 'config';

export default function MobileHeader(props) {
  const { show, setShow, buttonText, getButtonColor } = props;
  const { brokerLoginUrl } = config.app;

  return (
    <>
      <div
        className={`hamburger-menu-floating-button ${
          show
            ? 'hamburger-menu-floating-button-close'
            : 'hamburger-menu-floating-button-open'
        }`}
        onClick={() => setShow(!show)}></div>
      {show && (
        <div className='mobile-header-overlay'>
          {buttonText.map((item, key) => (
            <div className='mobile-header-button-item' key={key}>
              {key === 0 ? (
                <a href={brokerLoginUrl}>
                  <Button className={`btn btn-${getButtonColor(key)}`}>
                    {item.text}
                  </Button>
                </a>
              ) : (
                <Link to={item.props.url}>
                  <Button className={`btn btn-${getButtonColor(key)}`}>
                    {item.text}
                  </Button>
                </Link>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
}
