/** @format */

import React from 'react';
import { Col } from 'react-bootstrap';

export default function Input(props) {
  const {
    required,
    label,
    identifier,
    value,
    handleChange,
    disabled,
    type,
    placeholderOnly
  } = props;
  return (
    <Col className='modal-field-container' sm={12} md={6}>
      {label && !placeholderOnly && (
        <label
          htmlFor={identifier}
          className={`text3m ${required && 'required-label'}`}>
          {label}
        </label>
      )}
      {(type === 'text' || type === 'email') && (
        <input
          id={identifier}
          name={identifier}
          type={type}
          value={value}
          onChange={handleChange}
          placeholder={label}
          className='modal-field-input modal-description'
          disabled={disabled}
        />
      )}
    </Col>
  );
}
