/** @format */

import React from 'react';
import Header from 'components/Layout/Header';
import Footer from 'components/Layout/Footer';
import ArticleLayout from 'components/ArticleLayout';
import Container from 'react-bootstrap/Container';

export default function ArticlePage(props) {
  const text = props.t('lang_registration:article1');
  const { isMobileView } = props;

  return (
    <>
      <div className='main-container'>
        <Header {...props} />
        <div className='inner-container'>
          <Container>
            <ArticleLayout
              text={text}
              isMobileView={isMobileView}
              banner={'images/image-article-page-1-1.png'}
              subIcon={'images/image-article-page-1-2.png'}
              subIcon1={'images/image-article-page-1-3.png'}
            />
          </Container>
        </div>

        <Footer {...props} />
      </div>
    </>
  );
}
