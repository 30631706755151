/** @format */

import React from 'react';
import Header from 'components/Layout/Header';
import Footer from 'components/Layout/Footer';
import Container from 'react-bootstrap/Container';

export default function About(props) {
  const text = props.t('lang_registration:aboutus');
  const { isMobileView } = props;

  const getClassNameSection = isMobileView
    ? 'mobile-aboutuspage-section'
    : 'aboutuspage-section';
  return (
    <>
      <div className='main-container'>
        <Header {...props} />
        <div className='inner-container'>
          <Container>
            <>
              <div className='aboutuspage'>
                <h2 className='aboutuspage-main-title'>{text.heading}</h2>
                <p className='text2r'>{text.main_para}</p>
              </div>

              <div
                className={`${getClassNameSection} ${
                  isMobileView
                    ? 'mobile-aboutuspage-section-1'
                    : 'aboutuspage-section-1'
                }`}>
                <div
                  className={`aboutuspage-innersections ${
                    !isMobileView && 'aboutuspage-text-right-spacing'
                  }`}>
                  <p className={`text2r`}>{text.section_1.text}</p>
                </div>
                <div className='aboutuspage-innersections'>
                  <img
                    src={`images/image-about-us-1.png`}
                    className='aboutuspage-innersections-img'
                  />
                </div>
              </div>

              <div>
                <h3 className='aboutuspage-sub-title'>
                  {text.section_2.heading}
                </h3>

                <p className='text2r'>{text.section_2.description}</p>

                <div
                  className={`${getClassNameSection} ${
                    isMobileView
                      ? 'mobile-aboutuspage-section-1'
                      : 'aboutuspage-section-1'
                  }`}>
                  <div className='aboutuspage-innersections'>
                    <img
                      src={`images/image-about-us-2.png`}
                      className='aboutuspage-innersections-img'
                    />
                  </div>
                  <div
                    className={`aboutuspage-innersections ${
                      isMobileView
                        ? 'aboutuspage-text-top-spacing'
                        : 'aboutuspage-text-left-spacing'
                    }`}>
                    <p className={`text2r`}>{text.section_2.text}</p>
                  </div>
                </div>
              </div>

              <div className='aboutuspage-section-2'>
                <h3 className='aboutuspage-sub-title'>
                  {text.section_3.heading}
                </h3>

                <p className='text2r'>{text.section_3.description}</p>
              </div>
            </>
          </Container>
        </div>

        <Footer {...props} />
      </div>
    </>
  );
}
