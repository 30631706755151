/** @format */
/** A custom hook for publicly accessible routes without requiring authentictation */

import React from 'react';
import config from 'config';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

export default function PublicRoute({ children, ...rest }) {
  const { data = {}, isAuthenticated = false } = useSelector(
    (state) => state.session || {}
  );
  const isLoggedIn = data && isAuthenticated;

  if (isLoggedIn) {
    return <Redirect to={config.app.defaultPathName} />;
  } else {
    return <Route {...rest}>{children}</Route>;
  }
}

PublicRoute.propTypes = {
  children: PropTypes.node.isRequired
};
