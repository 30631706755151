/** @format */
/** A custom Hook that redirects users to private routes and redirects them to a given url when not authenticated */

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Route, Redirect, useLocation } from 'react-router-dom';

export default function PrivateRoute({ children, ...rest }) {
  const { pathname, search } = useLocation();

  const { data = {}, isAuthenticated } = useSelector((state) => state.session);

  const isLoggedIn = data && data.fid && isAuthenticated;

  return (
    <>
      <Route {...rest}>
        {isLoggedIn ? (
          <>{children}</>
        ) : (
          <Redirect to={`/?redirect=${pathname}${search}`} />
        )}
      </Route>
    </>
  );
}

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired
};
