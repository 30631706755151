/** @format */

import React from 'react';

export default function SocialIcons(props) {
  const { list = [] } = props;

  return (
    <>
      <div className='social-icon-container'>
        {list.map((item, key) => (
          <a
            key={key}
            href={item.props.url}
            className='social-icon'
            target='_blank'
            rel='noopener noreferrer'>
            <div className={`icon-${item.name}-light`}></div>
          </a>
        ))}
      </div>
    </>
  );
}
