/** @format */

import React from 'react';
import { Modal } from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTranslation } from 'react-i18next';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';

export default function ViewCalculationModal(props) {
  const { t } = useTranslation();
  const {
    heading,
    description,
    footer,
    showModal,
    setShowModal,
    amount,
    calculationUtils: {
      deductVat,
      deductFriaMaklareFees,
      deductEmployerContribution,
      deductIncomeTax
    }
  } = props;

  const vatDeductedAmount = Math.round(deductVat(amount));
  const friaMaklareFeesAmount = Math.round(
    deductFriaMaklareFees(vatDeductedAmount)
  );
  const employerFeesDeductedAmount = Math.round(
    deductEmployerContribution(friaMaklareFeesAmount)
  );
  const incomeTaxDeductedAmount = Math.round(
    deductIncomeTax(employerFeesDeductedAmount)
  );

  /**
   *
   * @param {*} key = iteration
   * @param {*} value  = initial amount
   * @param {*} result  = calculated result
   * @returns  translation text with the calculation step explained
   */

  const fetchTranslations = (key, value, result) => {
    return t(
      `lang_registration:homepage.feeCalculator.result.breakdown.items`,
      {
        amount: value,
        result: result
      }
    )[key].tooltip;
  };

  /**
   *
   * @param {*} key = iteration
   * @returns  = translation text with the calculation steps explained for all the steps
   */

  const getItemToolTip = (key) => {
    switch (key) {
      case 0:
        return fetchTranslations(key, amount, vatDeductedAmount);
      case 1:
        return fetchTranslations(key, vatDeductedAmount, friaMaklareFeesAmount);
      case 2:
        return fetchTranslations(
          key,
          friaMaklareFeesAmount,
          employerFeesDeductedAmount
        );
      case 3:
        return fetchTranslations(
          key,
          employerFeesDeductedAmount,
          incomeTaxDeductedAmount
        );
      default:
        return '';
    }
  };

  const toggleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div>
        <Modal
          className='message-modal fee-calculator-modal'
          show={showModal}
          onHide={toggleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title className='message-modal-header'>
              {heading}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ul>
              {description.map((item, key) => (
                <li key={key}>
                  - {item.text}{' '}
                  <OverlayTrigger
                    placement='top'
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id='button-tooltip'>
                        {getItemToolTip(key)}
                      </Tooltip>
                    }>
                    <FontAwesomeIcon icon={faCircleQuestion} className='thin' />
                  </OverlayTrigger>
                </li>
              ))}
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <p className='fee-calculator-modal-footer'>{footer}</p>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
