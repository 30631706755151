/** @format */

import React from 'react';
import FeeCalculator from 'views/Registration/HomePage/FeeCalculator';
import PageLayout from 'components/Layout/Page';

export default function SalaryCalculatorPage(props) {
  const { tprop } = props;

  return (
    <PageLayout {...props} mainContainerClassName='homepage-view'>
      <FeeCalculator {...props} tprop={tprop.feeCalculator} salaryPage={true} />
    </PageLayout>
  );
}
