/** @format */

import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchViewingTimeSlotsService } from 'services/apiService';
import * as actionTypes from 'stateContainer/action-types';

function* workerSaga({ payload }) {
  try {
    const response = yield call(fetchViewingTimeSlotsService, payload);
    if (response.data.status == 'success') {
      yield put({
        type: actionTypes.VIEWING_TIME_SLOTS_SUCCESS,
        payload: response.data.data
      });
    } else {
      throw new Error('Fetch broker failed');
    }
  } catch (error) {
    yield put({ type: actionTypes.VIEWING_TIME_SLOTS_ERROR, error });
  }
}

export function* viewingTimeSlotsSaga() {
  yield takeLatest(actionTypes.VIEWING_TIME_SLOTS_REQUESTED, workerSaga);
}
