/** @format */

import React from 'react';
import Card from 'react-bootstrap/Card';
import { EqualHeightElement } from 'react-equal-height';
import sampleImage from '../../../../assets/images/images/image-sample-profile-object.png';
import { sample } from 'lodash';
import getPropertyTypeTranslation from '../../../../utils/getPropertyTypeTranslation';

export default function ImageCard(props) {
  const {
    type,
    //price, not used anymore
    startingPrice,
    finalPrice,
    location,
    bedrooms,
    bathrooms,
    dimensions,
    currency,
    status,
    imageUrl,
    dimensionsUnit,
    onComplete,
    isMobile,
    translation
  } = props;

  const getDisplayPrice = () => {
    if (finalPrice > 0) {
      return finalPrice;
    } else {
      return startingPrice;
    }
  };

  const displayPrice = getDisplayPrice();

  return (
    <Card className='listing-box'>
      {!isMobile ? (
        <div className='listing-box-img-container'>
          <Card.Img
            variant='top'
            className='listing-box-img'
            onLoad={onComplete}
            src={imageUrl.length === 0 ? sample : imageUrl}
          />

          {status === 'Sold' && (
            <p className='listing-box-sold-tag text3m'>SOLD</p>
          )}
        </div>
      ) : (
        <EqualHeightElement>
          <div className='listing-box-img-container'>
            <Card.Img
              variant='top'
              className='listing-box-img'
              onLoad={onComplete}
              src={imageUrl.length === 0 ? sampleImage : imageUrl}
            />

            {status === 'Sold' && (
              <p className='listing-box-sold-tag text3m'>SOLD</p>
            )}
          </div>
        </EqualHeightElement>
      )}

      <Card.Body>
        <p className='listing-type  text2r'>
          {getPropertyTypeTranslation(type, translation)}
        </p>
        <p className='listing-location '>
          {typeof location === 'string' && location !== ''
            ? location.length > 15
              ? `${location.substring(0, 15)}...`
              : location
            : '\u00A0'}
        </p>

        <h3 className='listing-price'>{displayPrice + ' ' + currency}</h3>

        <div className='listing-type  text2r'>
          <div className='icon-strip-container'>
            <div className='icon-strip-box'>
              <div className='icon-strip-icon icon-bedrooms '></div>
              <div className='icon-strip-text'>{bedrooms ? bedrooms : ''}</div>
            </div>
            <div className='icon-strip-box'>
              <div className='icon-strip-icon icon-bathrooms '></div>
              <div className='icon-strip-text '>
                {bathrooms ? bathrooms : ''}
              </div>
            </div>

            <div className='icon-strip-box'>
              <div className='icon-strip-icon icon-dimensions '></div>
              <div className='icon-strip-text'>{`${
                dimensions ? dimensions : ''
              } ${dimensions ? dimensionsUnit : ''}`}</div>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}
