/** @format */

import React from 'react';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';

export default function Benefits(props) {
  const { tprop } = props;

  return (
    <div className='homepage-benefits-container'>
      <CardGroup>
        {tprop.map((item, key) => (
          <Card className={`benefits-item benefits-item-${key + 1} `} key={key}>
            {item.props.imagePosition === 'top' && (
              <div className={`icon-benefits icon-benefits-item-${key + 1}`} />
            )}
            <Card.Body>
              <Card.Title>
                <h4>{item.heading.text}</h4>
              </Card.Title>
              <Card.Text className='text3m'>{item.description.text}</Card.Text>
            </Card.Body>

            {item.props.imagePosition === 'bottom' && (
              <div className={`icon-benefits icon-benefits-item-${key + 1}`} />
            )}
          </Card>
        ))}
      </CardGroup>
    </div>
  );
}
