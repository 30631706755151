/** @format */

import React from 'react';

import Tooltip from 'react-bootstrap/Tooltip';
import Overlay from 'react-bootstrap/Overlay';

export default function ContactIcons(props) {
  const {
    brokerPhone,
    brokerEmail,
    onClickCopyProfile,
    target,
    showCopySuccess,
    copyProfileUrlSuccess
  } = props;
  return (
    <div className='contact-icons-container'>
      <a href={`tel:${brokerPhone}`} className='icon-phone-pink'></a>
      <a href={`mailto:${brokerEmail}`} className='icon-email-pink'></a>
      <div
        className='profile-heading-icon icon-copy-pink'
        onClick={() => onClickCopyProfile()}
        ref={target}>
        <Overlay
          target={target.current}
          show={showCopySuccess}
          placement='right'>
          {(props) => (
            <Tooltip id='copy-success-overlay' {...props}>
              {copyProfileUrlSuccess}
            </Tooltip>
          )}
        </Overlay>
      </div>
    </div>
  );
}
