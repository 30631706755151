/** @format */

import AlertBox from 'components/Alert';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { AiOutlineExclamationCircle } from 'react-icons/ai';

export default function AlertBlock(props) {
  const { formError, formErrorMessage, formSuccess, formSuccessMessage } =
    props;
  return (
    <>
      {formError ? (
        <Row>
          <Col>
            <AlertBox
              type='danger'
              text={formErrorMessage}
              icon={<AiOutlineExclamationCircle />}
              iconConfig={{ size: '18px' }}
            />
          </Col>
        </Row>
      ) : null}
      {formSuccess ? (
        <Row>
          <Col>
            <AlertBox
              type='success'
              text={formSuccessMessage}
              icon={<AiOutlineExclamationCircle />}
              iconConfig={{ size: '18px' }}
            />
          </Col>
        </Row>
      ) : null}
    </>
  );
}
