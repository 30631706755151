/** @format */

import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchBrokerProfileService } from 'services/apiService';
import * as actionTypes from 'stateContainer/action-types';
import { push } from 'connected-react-router';

function* workerSaga({ payload }) {
  try {
    const response = yield call(fetchBrokerProfileService, payload);
    if (response.data.status == 'success') {
      yield put({
        type: actionTypes.BROKER_PROFILE_SUCCESS,
        payload: response.data.data
      });
    } else {
      throw new Error('Fetch broker profile failed');
    }
  } catch (error) {
    yield put({ type: actionTypes.BROKER_PROFILE_ERROR, error });
    yield put(push('/404'));
  }
}

export function* brokerProfileSaga() {
  yield takeLatest(actionTypes.BROKER_PROFILE_REQUESTED, workerSaga);
}
