/** @format */

import React from 'react';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLongArrowRight,
  faLongArrowLeft
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { getImagePosition } from 'utils/layout';

export default function ArticleLayout({
  banner,
  subIcon,
  subIcon1,
  text,
  isMobileView
}) {
  const getClassNameSection = isMobileView
    ? 'mobile-articlepage-section'
    : 'articlepage-section';

  function getBtnColor(index) {
    let buttonColor = 'btn-white';
    if (index == 1) {
      buttonColor = 'btn-pink';
    }

    return buttonColor;
  }

  return (
    <>
      <div className='articlepage'>
        <h2 className='articlepage-main-title'>{text.heading}</h2>
        <img
          src={banner}
          className='articlepage image-article-page-1'
          alt='header image'
        />
        <p className='text2r'>{text.main_para}</p>
      </div>

      {text.descriptions.map(({ text: articleText, props }, key) => (
        <div key={key}>
          {isMobileView ? (
            <div
              className={`${getClassNameSection} articlepage-text-bottom-spacing`}>
              <img
                src={`${key > 0 ? subIcon1 : subIcon}`}
                className='mobile-articlepage-image-spacing'
                alt={props.alt}
              />

              <p className={`text2r`}>{articleText}</p>
            </div>
          ) : (
            <div
              className={`${getClassNameSection} articlepage-text-bottom-spacing`}>
              {props.imagePosition === 'left' && (
                <img src={subIcon1} alt={props.alt} />
              )}
              <p
                className={`text2r articlepage-text-${getImagePosition(
                  props.imagePosition
                )}-spacing`}>
                {articleText}
              </p>
              {props.imagePosition === 'right' && (
                <img src={subIcon} alt={props.alt} />
              )}
            </div>
          )}
        </div>
      ))}

      <div className='articlepage-desc'>
        <p className='text2r'>{text.second_para}</p>
      </div>
      <div className='mobile-articlepage-desc'>
        <p className='text2r'>{text.second_para}</p>
      </div>
      <div
        className={`${
          isMobileView
            ? 'mobile-articlepage-section-buttons'
            : 'articlepage-section-buttons'
        }`}>
        {text.buttons.map((button, index) => (
          <div key={index}>
            <div
              className={`button-container ${
                isMobileView
                  ? 'mobile-articlepage-button'
                  : 'articlepage-button-spacing-right'
              }`}>
              <Link to={button.url}>
                <Button
                  className={`btn ${getBtnColor(index)} ${
                    isMobileView && 'mobile-articlepage-fullwidth-buttons'
                  }`}>
                  {index === 0 && (text.id === 2 || text.id === 3) && (
                    <FontAwesomeIcon icon={faLongArrowLeft} size='lg' />
                  )}

                  {button.text}
                  {(index === 1 || index === 2) &&
                    (text.id === 1 || text.id === 2) && (
                      <FontAwesomeIcon icon={faLongArrowRight} size='lg' />
                    )}
                </Button>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
