/** @format */

import React from 'react';
import ModalWrapper from '..';
import moment from 'moment';
import 'moment/locale/sv';
import { useTranslation } from 'react-i18next';

export default function BiddingsModal(props) {
  const { bids, open, openBidding } = props;

  const closeModal = () => {
    openBidding(false);
  };

  const { t } = useTranslation();
  const text = t('lang_common:object_listing:modals:bidding');

  moment.locale('sv', {
    months:
      'Januari_Februari_Mars_April_Maj_Juni_Juli_Augusti_September_Oktober_November_December'.split(
        '_'
      ),
    monthsParseExact: true,
    weekdays: 'Söndag_Måndag_Tisdag_Onsdag_Torsdag_Fredag_Lördag'.split('_'),

    weekdaysParseExact: true
  });

  return (
    <>
      <ModalWrapper
        open={open}
        openModal={closeModal}
        heading={text.heading.label}>
        <table className='table table-responsive table-bordered'>
          <thead>
            <tr>
              <th>{text.alias.label}</th>
              <th>{text.amount.label}</th>
              <th>{text.dateTime.label}</th>
            </tr>
          </thead>
          <tbody>
            {bids.map((bid) => {
              const startDate = moment(bid.dateAndTime)
                .locale('sv')
                .format('dddd, DD/MM/YY [kl] HH:mm');
              const endDate = moment(bid.endTime).format('HH:mm');
              return (
                <tr key={bid.id}>
                  <td>{bid.alias}</td>
                  <td>{bid.amount}</td>
                  <td className='text-capitalize'>
                    {`${startDate} - ${endDate}`}
                  </td>
                </tr>
              );
            })}
            {/* {bids.map((bid) => {
              return (
                <tr key={bid.id}>
                  <td>{bid.alias}</td>
                  <td>{bid.amount}</td>
                  <td className='text- capitalize'>
                    {moment(bid.dateAndTime)
                      .locale('sv')
                      .format('dddd, DD/MM/YY [kl] HH:mm')}
                  </td>
                </tr>
              );
            })} */}
          </tbody>
        </table>
      </ModalWrapper>
    </>
  );
}
