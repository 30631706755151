/** @format */

import * as actionTypes from 'stateContainer/action-types';

const initialState = {
  data: [],
  isFetching: false,
  isError: false
};

export const brokerProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.BROKER_PROFILE_REQUESTED:
    case actionTypes.BROKER_PROFILE_BY_SLUG_REQUESTED:
      return { ...state, isFetching: true };

    case actionTypes.BROKER_PROFILE_SUCCESS:
    case actionTypes.BROKER_PROFILE_BY_SLUG_SUCCESS:
      return { ...state, data: action.payload, isFetching: false };

    case actionTypes.BROKER_PROFILE_ERROR:
    case actionTypes.BROKER_PROFILE_BY_SLUG_ERROR:
      return { ...state, isFetching: false, isError: true };

    default:
      return state;
  }
};
