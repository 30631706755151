/** @format */

import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import Layout from 'components/Layout/Onboarding/Layout.js';
import OnboardingFooter from 'components/Layout/Onboarding/Footer';
import { useFormik } from 'formik';

import AlertBox from 'components/Alert';
import { AiOutlineExclamationCircle } from 'react-icons/ai';

import { history } from 'stateContainer/history';
import { useLocalStorage } from 'utils/hooks';

export default function Presentation({ isMobileView, ...props }) {
  const {
    pageId,
    heading,
    step_description,
    note_heading,
    note_description,
    buttons,
    form_label,
    placeholder
  } = props.t('lang_onboarding:presentation');
  const errorMessage = props.t('lang_onboarding:formErrorMessage');

  const stepperText = props.t('lang_onboarding:stepper');
  const nextPageUrl = buttons[1].url;

  const [formError, setFormError] = useState(false);

  const [description, setDescription] = useLocalStorage('description', '');

  const formik = useFormik({
    initialValues: {
      description: description ?? ''
    },
    onSubmit: (values) => {
      if (values.description) {
        setDescription(values.description);

        setFormError(false);
        history.push(nextPageUrl);
      } else {
        setFormError(true);
      }
    }
  });

  return (
    <Layout
      isMobileView={isMobileView}
      stepperText={stepperText}
      pageId={pageId}
      heading={heading}
      step_description={step_description}
      note_heading={note_heading}
      note_description={note_description}>
      <form>
        <Row>
          {formError && (
            <AlertBox
              type='danger'
              text={errorMessage}
              icon={<AiOutlineExclamationCircle />}
              iconConfig={{ size: '18px' }}
            />
          )}
          <Col md={12}>
            <div className='onboardingsteps-column-container'>
              <label htmlFor='firstName' className='text3m'>
                {form_label}
              </label>
              <textarea
                id='description'
                name='description'
                type='text'
                value={formik.values.description}
                onChange={formik.handleChange}
                placeholder={placeholder}
                className={`onboarding-field-textarea`}
              />
            </div>
          </Col>
          <Col md={6}></Col>
        </Row>
      </form>
      <OnboardingFooter
        buttons={buttons}
        btnPrevFunction={() => history.push(buttons[0].url)}
        btnNextFunction={() => formik.handleSubmit()}
      />
    </Layout>
  );
}
