/**
 *
 * @format
 */

/** @format */
import React, { useState } from 'react';
import { Accordion, Col, Row, Button, Alert } from 'react-bootstrap';

import { useFormik } from 'formik';
import * as Yup from 'yup';
// import { Modal } from 'react-bootstrap';

import PageLayout from 'components/Layout/Page';
import { regions } from '../../../Onboarding/Onboarding/ActiveAreas/regions';
import { createLeadApiService } from 'services/apiService';
import ButtonSyncLoader from 'components/ButtonSyncLoader';
import ModalWrapper from 'components/Modals';

const ImportantFieldMark = () => <span style={{ color: 'red' }}>*</span>;

const LeadTypes = {
  SELL: 'SELL',
  BUY: 'BUY',
  FREE_VALUATION: 'FREE_VALUATION'
};

const FormSubmitStates = {
  INITIAL: 'INITIAL',
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL'
};

export const LeadSources = {
  FM_HOME: 'fm_home',
  FM_SELL_AND_BUY: 'fm_sell_and_buy',
  FM_BROKER_PROFILE: 'fm_broker_profile',
  FM_OBJECT_PROFILE: 'fm_object_profile'
};

const Messages = {
  PLEASE_SELECT_TYPE: 'Välj vad du behöver hjälp med',
  PLEASE_TYPE_NAME: 'Du måste ange ditt namn',
  PLEASE_TYPE_EMAIL: 'Du måste ange din mejladress',
  EMAIL_IS_NOT_VALID: 'Mejladresssen är inte giltig',
  PLEASE_SELECT_CITY: 'Du måste välja en ort',
  SUCCESSFULLY_SENT: 'Allt gick bra och en mäklare kontaktar dig',
  ERROR_OCCURRED: 'Ajdå, något gick fel. Testa igen.'
};

const LeadsSectionBody = (props) => {
  const text = props.t('lang_registration:register');

  const [leadType, setLeadType] = useState(null);

  const [formSubmitState, setFormSubmitState] = useState(
    FormSubmitStates.INITIAL
  );

  const leadsFormValidationSchema = () =>
    Yup.object().shape({
      leadType: Yup.string().required(Messages.PLEASE_SELECT_TYPE),
      name: Yup.string().required(Messages.PLEASE_TYPE_NAME),
      email: Yup.string()
        .email(Messages.EMAIL_IS_NOT_VALID)
        .required(Messages.PLEASE_TYPE_EMAIL),
      city: Yup.string().required(Messages.PLEASE_SELECT_CITY)
    });

  const formik = useFormik({
    initialValues: {
      leadType: leadType || '',
      name: '',
      email: '',
      city: '',
      details: ''
    },
    onSubmit: (values, { resetForm }) => {
      const checkIsReady = () => {
        return leadType && values.name && values.email && values.city;
      };

      if (checkIsReady()) {
        setFormSubmitState(FormSubmitStates.PENDING);
        createLeadApiService({
          leadType,
          name: values.name,
          email: values.email,
          location: values.city,
          details: values.details,
          source: props.metadata?.source || undefined,
          brokerId: props.metadata?.brokerId || undefined,
          objectId: props.metadata?.objectId || undefined
        })
          .then((response) => {
            if (response?.data?.status === 'success') {
              resetForm();
              setLeadType(null);
              setFormSubmitState(FormSubmitStates.SUCCESS);
              setTimeout(
                () => setFormSubmitState(FormSubmitStates.INITIAL),
                5000
              );
            } else {
              setFormSubmitState(FormSubmitStates.FAIL);
            }
          })
          .catch(() => {
            setFormSubmitState(FormSubmitStates.FAIL);
          });
      }
    },
    validationSchema: leadsFormValidationSchema,
    validateOnChange: true,
    validateOnBlur: false
  });

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  const leadTypeSelectHandler = (type) => {
    setLeadType(type);
    formik.setFieldValue('leadType', type, true);
  };

  const leadTypeIndicator = (type) => {
    if (type === leadType) {
      return 'btn-pink';
    }

    return 'btn-white';
  };

  const LeadSelector = ({ leadType, children }) => (
    <div className='lead-selector-button-wrapper'>
      <Button
        className={`btn ${leadTypeIndicator(leadType)} lead-selector-button`}
        onClick={() => leadTypeSelectHandler(leadType)}>
        {children}
      </Button>
    </div>
  );

  const FormElementErrorSection = ({ formikError }) => (
    <label className='registerpage-description registerpage-validation-error-label'>
      {formikError != undefined && formikError}
    </label>
  );

  const FormSubmitAlert = () => {
    const displayAlert = () => {
      return (
        formSubmitState === FormSubmitStates.SUCCESS ||
        formSubmitState === FormSubmitStates.FAIL
      );
    };

    const { variant, message } = (() => {
      let response = {
        variant: null,
        message: ''
      };
      if (formSubmitState === FormSubmitStates.SUCCESS) {
        response.variant = 'success';
        response.message = Messages.SUCCESSFULLY_SENT;
      } else if (formSubmitState === FormSubmitStates.FAIL) {
        response.variant = 'danger';
        response.message = Messages.ERROR_OCCURRED;
      }
      return response;
    })();

    return (
      displayAlert() && (
        <Alert variant={variant}>
          <label className='text3m'>{message}</label>
        </Alert>
      )
    );
  };

  return (
    <div className='leads-generator-body'>
      <Row>
        <Col className='registerpage-field-container'>
          <label className='text3m'>
            Hur kan vi hjälpa dig? <ImportantFieldMark />
          </label>
          <div className='leads-selector-button-group'>
            <LeadSelector leadType={LeadTypes.SELL}>Sälja</LeadSelector>
            <LeadSelector leadType={LeadTypes.BUY}>Köpa</LeadSelector>
            <LeadSelector leadType={LeadTypes.FREE_VALUATION}>
              Fri värdering
            </LeadSelector>
          </div>
          <FormElementErrorSection formikError={formik.errors.leadType} />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={12}>
          <form>
            <Row>
              <Col className='registerpage-field-container'>
                <label htmlFor='lastName' className='text3m'>
                  Namn <ImportantFieldMark />
                </label>
                <input
                  id='name'
                  name='name'
                  type='text'
                  placeholder='För- och efternamn'
                  className='registerpage-field-input'
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />
                <FormElementErrorSection formikError={formik.errors.name} />
              </Col>
            </Row>

            <Row>
              <Col className='registerpage-field-container'>
                <label htmlFor='email' className='text3m'>
                  {text.form_label_3} <ImportantFieldMark />
                </label>
                <input
                  id='email'
                  name='email'
                  type='text'
                  placeholder={text.placeholder}
                  className='registerpage-field-input'
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
                <FormElementErrorSection formikError={formik.errors.email} />
              </Col>
              <Col className='registerpage-field-container'>
                <label htmlFor='region' className='text3m'>
                  Ort <ImportantFieldMark />
                </label>
                <select
                  id='city'
                  name='city'
                  className='registerpage-field-input text2r'
                  onChange={formik.handleChange}>
                  <option key='' selected value='' defaultValue=''>
                    Välj här
                  </option>
                  {regions.map((value, index) => (
                    <option className='text2r' value={value} key={index}>
                      {value}
                    </option>
                  ))}
                </select>
                <FormElementErrorSection formikError={formik.errors.city} />
              </Col>
            </Row>

            <Row>
              <Col className='registerpage-field-container'>
                <label htmlFor='description' className='text3m'>
                  Berätta lite mer
                </label>
                <textarea
                  id='details'
                  name='details'
                  onChange={formik.handleChange}
                  value={formik.values.details}
                  placeholder='Om din bostad, vad söker du..'
                  className='leads-additional-info-textarea'
                />

                <FormSubmitAlert />
              </Col>
            </Row>
            <div
              className='registerpage-button-container'
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}>
              <Button
                className='btn btn-pink leads-submit-button mobile-registerpage-button-width'
                type='button'
                disabled={formSubmitState === FormSubmitStates.PENDING}
                onClick={handleSubmit}>
                {formSubmitState === FormSubmitStates.PENDING ? (
                  <ButtonSyncLoader />
                ) : (
                  'Skicka till en fri mäklare'
                )}
              </Button>
            </div>
          </form>
        </Col>
      </Row>
    </div>
  );
};

export function LeadsGeneratorAccordion(props) {
  return (
    <div className='leads-generator-accordion-wrapper'>
      <Accordion className='leads-generator-accordion' alwaysOpen={false}>
        <Accordion.Item eventKey={0} key={0}>
          <Accordion.Header className='leads-generator-accordion-header'>
            <span className='leads-generator-accordion-header-title d-flex justify-content-center w-100'>
              Sälja | Köpa | Fri värdering
            </span>
          </Accordion.Header>
          <Accordion.Body>
            <LeadsSectionBody {...props} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

export function LeadsGeneratorPage(props) {
  return (
    <PageLayout {...props}>
      <div className='leads-generator-page-wrapper'>
        <LeadsSectionBody
          metadata={{
            source: LeadSources.FM_SELL_AND_BUY
          }}
          {...props}
        />
      </div>
    </PageLayout>
  );
}

export function LeadsGeneratorModal(props) {
  const { open, handler } = props;
  return (
    <ModalWrapper
      // heading={text.heading?.label}
      // description={text.description}
      className='leads-generator-modal'
      openModal={handler}
      open={open}>
      <LeadsSectionBody {...props} />
    </ModalWrapper>
  );
}
