/** @format */

import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import Container from 'react-bootstrap/Container';

export default function PageLayout(props) {
  const { children, mainContainerClassName, bottomSpacerHeight = 40 } = props;

  return (
    <>
      <div className={`main-container ${mainContainerClassName}`}>
        <Header {...props} />
        <Container fluid className='content-wrapper'>
          {children}
        </Container>
        <div
          style={{
            height: `${bottomSpacerHeight}px`
          }}></div>
        <Footer {...props} />
      </div>
    </>
  );
}
