/** @format */

import * as actionTypes from 'stateContainer/action-types';

const initialState = {
  viewingTimeSlots: {}
};

export const viewingTimeSlotsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.VIEWING_TIME_SLOTS_REQUESTED:
      return { ...state, isFetching: true };

    case actionTypes.VIEWING_TIME_SLOTS_SUCCESS:
      return { ...state, data: action.payload, isFetching: false };

    case actionTypes.VIEWING_TIME_SLOTS_ERROR:
      return { ...state, isFetching: false, isError: true };

    default:
      return state;
  }
};
