/** @format */
import React, { Fragment, useState } from 'react';
import { Container } from 'react-bootstrap';
import Header from 'components/Layout/Onboarding/Header';
import Personnummer from 'personnummer';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { useFormik } from 'formik';

import { history } from 'stateContainer/history';
import { useLocalStorage } from 'utils/hooks';
import AlertBox from 'components/Alert';
import { AiOutlineExclamationCircle } from 'react-icons/ai';

export default function SignUp(props) {
  const { header_title, form_fields, buttons } = props.t(
    'lang_onboarding:signup'
  );
  const errorMessage = props.t('lang_onboarding:formErrorMessage');
  const nextPageUrl = buttons[1].url;
  const [formError, setFormError] = useState(false);
  const [ssnError, setSsnError] = useState(false);
  const [socialSecurityNumber, setSocialSecurityNumber] = useLocalStorage(
    'socialSecurityNumber',
    ''
  );
  const [fmiProfileLink, setFmiProfileLink] = useLocalStorage(
    'fmiProfileLink',
    ''
  );
  const formik = useFormik({
    initialValues: {
      socialSecurityNumber: socialSecurityNumber ?? '',
      fmiProfileLink: fmiProfileLink ?? ''
    },
    onSubmit: (values) => {
      if (values.socialSecurityNumber && values.fmiProfileLink) {
        const socialSecurityNumberIsValid = Personnummer.valid(
          values.socialSecurityNumber
        );

        if (socialSecurityNumberIsValid) {
          setFmiProfileLink(values.fmiProfileLink);
          setSocialSecurityNumber(values.socialSecurityNumber);
          setFormError(false);
          setSsnError(false);
          history.push(nextPageUrl);
        } else {
          setSsnError(true);
          setFormError(true);
        }
      } else {
        setFormError(true);
      }
    }
  });

  function handleSubmit() {
    formik.handleSubmit();
  }

  return (
    <Container
      fluid
      className='onboardingagreement-main-container onboarding-main-container-spacing'>
      <Header header_title={header_title} />
      <Container>
        <form className='onboardingsteps-content-body'>
          <Row>
            <Col md={3}></Col>
            <Col md={6}>
              {formError && (
                <AlertBox
                  type='danger'
                  text={errorMessage}
                  icon={<AiOutlineExclamationCircle />}
                  iconConfig={{ size: '18px' }}
                />
              )}
              {form_fields.map(({ form_label, placeholder, id }, index) => (
                <Row key={index}>
                  <label htmlFor={id} className='text3m'>
                    {form_label}
                  </label>
                  <input
                    id={id}
                    name={id}
                    type='text'
                    maxLength={id === 'socialSecurityNumber' ? 16 : 100}
                    value={
                      index === 0
                        ? formik.values.socialSecurityNumber
                        : formik.values.fmiProfileLink
                    }
                    onChange={formik.handleChange}
                    placeholder={placeholder}
                    className={`onboardingsignup-field-input ${
                      index === 0 && 'onboardingsignup-field-input-spacing'
                    } ${index === 0 && ssnError && 'ssnError'}`}
                  />
                </Row>
              ))}
            </Col>
            <Col md={3}></Col>
          </Row>

          <div className='onboardingsignup-button-container'>
            {buttons.map(({ label, url }, index) => (
              <Fragment key={index}>
                <Button
                  className={`btn ${
                    index === 0
                      ? 'btn-white'
                      : 'btn-pink onboardingsignup-button-spacing'
                  }`}
                  onClick={() =>
                    index === 0 ? history.push(url) : handleSubmit()
                  }>
                  {index === 0 && (
                    <FontAwesomeIcon icon={faLongArrowLeft} size='lg' />
                  )}
                  {label}
                </Button>
              </Fragment>
            ))}
          </div>
        </form>
      </Container>
    </Container>
  );
}
