/** @format */

import React, { Fragment, useState } from 'react';

import { Row, Col } from 'react-bootstrap';

import Layout from 'components/Layout/Onboarding/Layout.js';
import OnboardingFooter from 'components/Layout/Onboarding/Footer';
import { useFormik } from 'formik';
import { history } from 'stateContainer/history';

import AlertBox from 'components/Alert';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { useLocalStorage } from 'utils/hooks';
import { validateOnlyNumbers } from 'utils/validations';
import { postcodes } from './postcodes';

export default function BillingAddress({ isMobileView, ...props }) {
  const {
    heading,
    step_description,
    note_heading,
    note_description,
    buttons,
    form_fields,
    pageId
  } = props.t('lang_onboarding:billingaddress');

  const errorZipMessage = props.t('lang_onboarding:formZipCodeErrorMessage');
  const errorCityMessage = props.t('lang_onboarding:formCityErrorMessage');

  const stepperText = props.t('lang_onboarding:stepper');
  const nextPageUrl = buttons[1].url;

  const [formError, setFormError] = useState('');

  const [addressLine1, setAddressLine1] = useLocalStorage('addressLine1', '');
  const [addressLine2, setAddressLine2] = useLocalStorage('addressLine2', '');
  const [zipCode, setZipCode] = useLocalStorage('zipCode', '');
  const [city, setCity] = useLocalStorage('city', '');
  const [country, setCountry] = useLocalStorage('country', '');

  const formik = useFormik({
    initialValues: {
      addressLine1: addressLine1 ?? '',
      addressLine2: addressLine2 ?? '',
      zipCode: zipCode ?? '',
      city: city ?? '',
      country: country ?? ''
    },
    onSubmit: (values) => {
      if (!validateOnlyNumbers(values.zipCode) || !values.zipCode) {
        setFormError(errorZipMessage);
        return;
      }

      if (!values.city) {
        setFormError(errorCityMessage);
        return;
      }

      setAddressLine1(values.addressLine1);
      setAddressLine2(values.addressLine2);
      setZipCode(values.zipCode);
      setCity(values.city);
      setCountry('Sweden'); //hardcoded Sweden as requested by Jens on 06/12/2022
      setFormError('');
      history.push(nextPageUrl);
    }
  });

  function splitDropdowns(index, form_label, id, placeholder, values) {
    if (index === 2) {
      return (
        <div className='onboardingsteps-column-container'>
          <label htmlFor='firstName' className='text3m'>
            {form_label}
          </label>
          <input
            id={id}
            name={id}
            value={getInputValue(index)}
            onChange={formik.handleChange}
            type='text'
            placeholder={placeholder}
            className={`onboardingsignup-field-input`}
          />
        </div>
      );
    }

    return (
      <div className='onboardingsteps-column-container'>
        <label htmlFor='firstName' className='text3m'>
          {form_label}
        </label>
        <select
          value={getInputValue(index)}
          onChange={formik.handleChange}
          className='onboardingsignup-field-input text2r'
          id={id}
          name={id}>
          <option selected value='' defaultValue=''>
            {placeholder}
          </option>
          {values.map((value, index) => (
            <option className='text2r' value={value} key={index}>
              {value}
            </option>
          ))}
        </select>
      </div>
    );
  }

  function getInputValue(index) {
    switch (index) {
      case 0:
        return formik.values.addressLine1;
      case 1:
        return formik.values.addressLine2;
      case 2:
        return formik.values.zipCode;
      case 3:
        return formik.values.city;
      case 4:
        return formik.values.country;

      default:
        break;
    }
  }

  // changed for better sonarqube performance
  const getInputBlock = (id, form_label, placeholder, values, index) => {
    if (index < 2) {
      return (
        <div className='onboardingsteps-column-container'>
          <label htmlFor='firstName' className='text3m'>
            {form_label}
          </label>
          <input
            id={id}
            name={id}
            value={getInputValue(index)}
            onChange={formik.handleChange}
            type='text'
            placeholder={placeholder}
            className={`onboardingsignup-field-input`}
          />
        </div>
      );
    }
    if (index === 3) {
      return (
        <Col>
          {splitDropdowns(index, form_label, id, placeholder, postcodes)}
        </Col>
      );
    }
    return (
      <Col>{splitDropdowns(index, form_label, id, placeholder, values)}</Col>
    );
  };

  return (
    <Layout
      isMobileView={isMobileView}
      stepperText={stepperText}
      pageId={pageId}
      heading={heading}
      step_description={step_description}
      note_heading={note_heading}
      note_description={note_description}>
      <form>
        <Row>
          {formError.length > 0 && (
            <AlertBox
              type='danger'
              text={formError}
              icon={<AiOutlineExclamationCircle />}
              iconConfig={{ size: '18px' }}
            />
          )}
          {form_fields.map(({ id, form_label, placeholder, values }, index) => (
            <Fragment key={index}>
              {getInputBlock(id, form_label, placeholder, values, index)}
            </Fragment>
          ))}
        </Row>
      </form>
      <OnboardingFooter
        buttons={buttons}
        btnPrevFunction={() => history.push(buttons[0].url)}
        btnNextFunction={() => formik.handleSubmit()}
      />
    </Layout>
  );
}
