/** @format */

import React, { useState } from 'react';
import Header from 'components/Layout/Header';
import Footer from 'components/Layout/Footer';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useFormik } from 'formik';
import AlertBox from 'components/Alert';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import ButtonSyncLoader from 'components/ButtonSyncLoader';
import { formikHelper } from 'utils/formikHelper';
import { validatePhoneNumberWithZero } from 'utils/validations';

export default function Contact(props) {
  const text = props.t('lang_registration:contactUs');
  const [formError, setFormError] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formSuccess, setformSuccess] = useState(false);

  const formikHook = useFormik;

  const formik = formikHelper(
    formikHook,
    {
      firstName: '',
      lastName: '',
      email: '',
      contactNo: '',
      message: ''
    },
    setFormSubmitted,
    setFormError,
    setformSuccess,
    'contactUsRequest',
    ['firstName', 'lastName', 'email', 'contactNo', 'message'],
    () => {},
    [{ key: 'contactNo', rule: validatePhoneNumberWithZero }]
  );

  return (
    <>
      <div className='main-container'>
        <Header {...props} />
        <div className='contactUs-inner-container'>
          <Container>
            <h3>{text.heading}</h3>
            <Row>
              <Col md={8}>
                <p className='text2r contactUs-description'>
                  {text.description}
                </p>
                <form onSubmit={formik.handleSubmit}>
                  {formError ? (
                    <Row>
                      <Col>
                        <AlertBox
                          type='danger'
                          text={text.formErrorMessage}
                          icon={<AiOutlineExclamationCircle />}
                          iconConfig={{ size: '18px' }}
                        />
                      </Col>
                    </Row>
                  ) : null}
                  {formSuccess ? (
                    <Row>
                      <Col>
                        <AlertBox
                          type='success'
                          text={text.formSuccessMessage}
                          icon={<AiOutlineExclamationCircle />}
                          iconConfig={{ size: '18px' }}
                        />
                      </Col>
                    </Row>
                  ) : null}
                  <Row>
                    <Col className='contactUs-field-container'>
                      <label
                        htmlFor='firstName'
                        className='text3m required-label'>
                        {text.form_label_1}
                      </label>
                      <input
                        id='firstName'
                        name='firstName'
                        type='text'
                        onChange={formik.handleChange}
                        value={formik.values.firstName}
                        placeholder={text.placeholder}
                        className='contactUs-field-input contactUs-description'
                        disabled={formSubmitted}
                      />
                    </Col>
                    <Col className='contactUs-field-container'>
                      <label
                        htmlFor='lastName'
                        className='text3m required-label'>
                        {text.form_label_2}
                      </label>
                      <input
                        id='lastName'
                        name='lastName'
                        type='text'
                        onChange={formik.handleChange}
                        value={formik.values.lastName}
                        placeholder={text.placeholder}
                        className='contactUs-field-input contactUs-description'
                        disabled={formSubmitted}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col className='contactUs-field-container'>
                      <label htmlFor='email' className='text3m required-label'>
                        {text.form_label_3}
                      </label>
                      <input
                        id='email'
                        name='email'
                        type='email'
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        placeholder={text.placeholder}
                        className='contactUs-field-input contactUs-description'
                        disabled={formSubmitted}
                      />
                    </Col>
                    <Col className='contactUs-field-container'>
                      <label
                        htmlFor='contactNo'
                        className='text3m required-label'>
                        {text.form_label_4}
                      </label>
                      <input
                        id='contactNo'
                        name='contactNo'
                        type='tel'
                        onChange={formik.handleChange}
                        value={formik.values.contactNo}
                        placeholder={text.placeholder}
                        className='contactUs-field-input contactUs-description'
                        disabled={formSubmitted}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col className='contactUs-field-container'>
                      <label htmlFor='message' className='text3m'>
                        {text.form_label_5}
                      </label>
                      <textarea
                        id='message'
                        name='message'
                        type='test'
                        onChange={formik.handleChange}
                        value={formik.values.message}
                        placeholder={text.placeholder}
                        className='contactUs-field-textarea contactUs-description'
                        disabled={formSubmitted}
                      />
                    </Col>
                  </Row>
                  <div className='contactUs-button-container'>
                    <Button
                      className={'btn btn-pink mobile-contactUs-button-width'}
                      type='submit'
                      disabled={formSubmitted}>
                      {formSubmitted ? (
                        <ButtonSyncLoader />
                      ) : (
                        text.buttons[1].text
                      )}
                    </Button>
                  </div>
                </form>
              </Col>
              <Col md={{ span: 3, offset: 1 }} xs={0}>
                <img
                  src='images/image-contact-us.svg'
                  className='contactUs-image-1'
                  alt='registration image'
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className='contact-us-fria-maklare-info'>
                  <div className='info-box'>
                    <p>{text.address}</p>
                    <p>{text.phone}</p>
                    <p>{text.email}</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <Footer {...props} />
      </div>
    </>
  );
}
