/** @format */

import { React } from 'react';
import { Row } from 'react-bootstrap';
import Input from '../Input';

export default function NameBlock(props) {
  const { formikValues, formikHandleChange, text, formSubmitted } = props;

  return (
    <>
      <Row>
        <Input
          label={text.firstName.label}
          identifier='firstName'
          required={true}
          type='text'
          value={formikValues.firstName}
          handleChange={formikHandleChange}
          disabled={formSubmitted}
        />
        <Input
          identifier='lastName'
          type='text'
          required={true}
          value={formikValues.lastName}
          handleChange={formikHandleChange}
          label={text.lastName.label}
          disabled={formSubmitted}
        />
      </Row>
    </>
  );
}
