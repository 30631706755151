/** @format */

import React from 'react';

export default function StepperBar({ stepperText, pageId, showImage }) {
  return (
    <div
      className={`onboardingsteps-side-nav ${
        !showImage && 'stepperbar-mobile-border-radius'
      }`}>
      <div
        className={`${
          !showImage
            ? 'stepperbar-mobile-grid-container'
            : 'stepperbar-container'
        }`}>
        {showImage && (
          <img
            src='../images/logo-fria-maklare-light.svg'
            alt='header image'
            className='stepperbar-header-image'
          />
        )}
        <ol className='c-stepper'>
          {stepperText.map((text, index) => (
            <li
              className={`c-stepper__item ${
                pageId < index && 'c-stepper__bgInactive'
              } ${pageId - 1 < index && 'c-stepper__lineInactive'}`}
              key={index}>
              <div className='c-stepper__content'>
                <span
                  className={`c-stepper__title text3m ${
                    pageId < index && 'c-stepper__title_opacity'
                  }`}>
                  {text}
                </span>
              </div>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
}
