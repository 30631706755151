/** @format */

import React from 'react';
import StepperBar from '../StepperBar';

import { updateShowStepperMobile } from 'stateContainer/actions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faClose } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';

export default function MobileStepper({ stepperText, pageId }) {
  const {
    stepper: { show }
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  return (
    <div>
      <div style={{ position: 'relative' }}>
        <div
          className={`stepper-mobile-main-container ${
            show && 'stepperbar-header-no-radius'
          }`}>
          <img
            src='../images/logo-fria-maklare-light.svg'
            alt='header image'
            className='stepper-mobile-header-image'
          />
          {show ? (
            <FontAwesomeIcon
              icon={faClose}
              size='lg'
              className='stepper-mobile-icon-spacing'
              onClick={() => dispatch(updateShowStepperMobile(false))}
            />
          ) : (
            <FontAwesomeIcon
              icon={faBars}
              size='lg'
              className='stepper-mobile-icon-spacing'
              onClick={() => dispatch(updateShowStepperMobile(true))}
            />
          )}
        </div>
        <div
          className={`${
            !show && 'stepper-mobile-overlay-closed'
          } stepper-mobile-overlay`}>
          <StepperBar stepperText={stepperText} pageId={pageId} />
        </div>
      </div>
    </div>
  );
}
