/** @format */

import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchBrokerObjectsBySlugService } from 'services/apiService';
import * as actionTypes from 'stateContainer/action-types';

function* workerSaga({ payload }) {
  try {
    const response = yield call(fetchBrokerObjectsBySlugService, payload);
    if (response.data.status == 'success') {
      yield put({
        type: actionTypes.BROKER_OBJECTS_BY_SLUG_SUCCESS,
        payload: response.data
      });
    } else {
      throw new Error('Fetch broker objects failed');
    }
  } catch (error) {
    yield put({ type: actionTypes.BROKER_OBJECTS_BY_SLUG_ERROR, error });
  }
}

export function* brokerObjectsBySlugSaga() {
  yield takeLatest(actionTypes.BROKER_OBJECTS_BY_SLUG_REQUESTED, workerSaga);
}
