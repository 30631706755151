/** @format */

import React from 'react';
import { Container } from 'react-bootstrap';

export default function Header({ header_title }) {
  return (
    <div className='onboardingwelcome-header'>
      <Container>
        <div className='onboardingwelcome-header-inner-container'>
          <img src='../images/logo-fria-maklare-light.svg' alt='header image' />
          <h3 className='onboardingwelcome-header-text'>{header_title}</h3>
        </div>
      </Container>
    </div>
  );
}
