/** @format */

import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import Layout from 'components/Layout/Onboarding/Layout.js';
import OnboardingFooter from 'components/Layout/Onboarding/Footer';
import { useFormik } from 'formik';
import { useLocalStorage } from 'utils/hooks';
import AlertBox from 'components/Alert';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { history } from 'stateContainer/history';
import { regions } from './regions';

export default function ActiveAreas({ isMobileView, ...props }) {
  const {
    heading,
    step_description,
    note_heading,
    note_description,
    buttons,
    form_fields,
    pageId
  } = props.t('lang_onboarding:activeareas');

  const stepperText = props.t('lang_onboarding:stepper');
  const errorMessage = props.t('lang_onboarding:formErrorMessage');

  const nextPageUrl = buttons[1].url;

  const [formError, setFormError] = useState(false);
  const [activeRegion, setActiveRegion] = useLocalStorage('activeRegion', '');
  const [activeCountry, setActiveCountry] = useLocalStorage(
    'activeCountry',
    'Sweden'
  ); //hardcoded Sweden as requested by Jens on 06/12/2022
  const [activeVisitingAddress, setActiveVistingAddress] = useLocalStorage(
    'activeVisitingAddress',
    ''
  );

  const formik = useFormik({
    initialValues: {
      activeRegion: activeRegion ?? '',
      activeCountry: activeCountry ?? '',
      activeVisitingAddress: activeVisitingAddress ?? ''
    },
    onSubmit: (values) => {
      if (
        values.activeRegion &&
        values.activeRegion !==
          (form_fields.length && form_fields[0].placeholder
            ? form_fields[0].placeholder
            : '')
      ) {
        setActiveRegion(values.activeRegion);
        setActiveCountry(activeCountry);
        setActiveVistingAddress(values.activeVisitingAddress);
        setFormError(false);
        history.push(nextPageUrl);
      } else {
        setFormError(true);
      }
    }
  });

  function getInputValue(index) {
    switch (index) {
      case 0:
        return formik.values.activeRegion;
      case 1:
        return formik.values.activeVisitingAddress;

      default:
        break;
    }
  }

  return (
    <Layout
      isMobileView={isMobileView}
      stepperText={stepperText}
      pageId={pageId}
      heading={heading}
      step_description={step_description}
      note_heading={note_heading}
      note_description={note_description}>
      <form>
        {formError && (
          <AlertBox
            type='danger'
            text={errorMessage}
            icon={<AiOutlineExclamationCircle />}
            iconConfig={{ size: '18px' }}
          />
        )}
        {form_fields.map(({ id, form_label, placeholder }, index) => (
          <Row key={index}>
            <Col md={index === 1 ? 0 : 6}>
              <div className='onboardingsteps-column-container'>
                <label htmlFor={id} className='text3m'>
                  {form_label}
                </label>
                {index < 1 ? (
                  <select
                    id={id}
                    value={getInputValue(index)}
                    className='onboardingsignup-field-input text2r'
                    onChange={formik.handleChange}>
                    <option
                      selected
                      value={placeholder}
                      defaultValue={placeholder}>
                      {placeholder}
                    </option>
                    {regions.map((value, index) => (
                      <option className='text2r' value={value} key={index}>
                        {value}
                      </option>
                    ))}
                  </select>
                ) : (
                  <input
                    id={id}
                    name={id}
                    value={getInputValue(index)}
                    onChange={formik.handleChange}
                    type='text'
                    placeholder={placeholder}
                    className={`onboardingsignup-field-input`}
                  />
                )}
              </div>
            </Col>
          </Row>
        ))}
      </form>
      <OnboardingFooter
        buttons={buttons}
        btnPrevFunction={() => history.push(buttons[0].url)}
        btnNextFunction={() => formik.handleSubmit()}
      />
    </Layout>
  );
}
