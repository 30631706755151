/** @format */

import React from 'react';
import { useDispatch } from 'react-redux';
import {
  fetchMoreBrokerObjects,
  fetchMoreBrokerObjectsBySlug
} from 'stateContainer/actions';
import { Button, Spinner } from 'react-bootstrap';

export default function FetchMore(props) {
  const dispatch = useDispatch();
  const showMoreText = props.t(`lang_common:broker_profile.showmore.text`);
  const { totalPages, page, profileId, profileSlug, isFetchingMore } = props;

  return (
    <div className='broker-profile-show-more-container'>
      {totalPages !== page && (
        <Button
          className='btn btn-white'
          onClick={() => {
            if (profileId) {
              dispatch(
                fetchMoreBrokerObjects({ brokerId: profileId, page: page + 1 })
              );
            } else if (profileSlug) {
              dispatch(
                fetchMoreBrokerObjectsBySlug({
                  brokerSlug: profileSlug,
                  page: page + 1
                })
              );
            }
          }}>
          {isFetchingMore ? (
            <Spinner
              animation='border'
              role='status'
              style={{ color: 'var(--color-primary-pink)' }}
            />
          ) : (
            showMoreText
          )}
        </Button>
      )}
    </div>
  );
}
