/** @format */

import React, { useEffect, useState } from 'react';
import Header from 'components/Layout/Header';
import Footer from 'components/Layout/Footer';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import ListGroup from 'react-bootstrap/ListGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

export default function FAQ(props) {
  const text = props.t('lang_registration:faq');
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  function filterQuestions() {
    return text.questions.filter(
      (question) =>
        question.category.toLowerCase() ===
        text.categories[selectedCategory].toLowerCase()
    );
  }

  function handleSearch(searchString) {
    setSearchQuery(searchString);

    const result = filterQuestions().filter(
      ({ heading, text: question }) =>
        heading.toLowerCase().includes(searchString.toLowerCase()) ||
        question.toLowerCase().includes(searchString.toLowerCase())
    );

    setFilteredQuestions(result);
  }

  useEffect(() => {
    setFilteredQuestions(filterQuestions);
  }, [selectedCategory]);

  return (
    <>
      <div className='main-container'>
        <Header {...props} />
        <div className='inner-container'>
          <Container>
            <div>
              <h3 className='faqpage-title'>{text.heading}</h3>
            </div>
            <Row>
              <Col md={3}>
                <Form className='faqpage-search-field'>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faSearch} color={'#b3b3b3'} />
                    </InputGroup.Text>
                    <Form.Control
                      type='text'
                      placeholder={text.searchInput}
                      value={searchQuery}
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                  </InputGroup>
                </Form>
                <div className='faqpage-category-list faqpage-spacing'>
                  <ListGroup variant='flush'>
                    {text.categories.map((category, index) => (
                      <ListGroup.Item
                        key={index}
                        className={selectedCategory === index && `pink-text`}
                        onClick={() => setSelectedCategory(index)}>
                        {category}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </div>
              </Col>
              <Col md={9}>
                <Accordion defaultActiveKey='0'>
                  {filteredQuestions.length === 0 ? (
                    <div className='faqpage-center'>
                      <p>{text.no_results}</p>
                    </div>
                  ) : (
                    filteredQuestions.map(
                      ({ heading, text: questionText }, key) => (
                        <Accordion.Item eventKey={key} key={key}>
                          <Accordion.Header>{heading}</Accordion.Header>
                          <Accordion.Body>{questionText}</Accordion.Body>
                        </Accordion.Item>
                      )
                    )
                  )}
                </Accordion>
              </Col>
            </Row>
            <div className='faqpage-contact'>
              <p className='text2r'>{text.contact_desc}</p>
              <Link to={text.button.url}>
                <Button className={'btn btn-pink'}>{text.button.text}</Button>
              </Link>
            </div>
          </Container>
        </div>
        <Footer {...props} />
      </div>
    </>
  );
}
