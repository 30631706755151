/**
 * takes any value and returns only numeric
 *
 * @format
 * @param {String} value
 * @returns parsed Integer
 */

export const getNumericValue = (value) => {
  return value.replace(new RegExp(/[^\d]/, 'ig'), '');
};
