/** @format */

export const validatePhoneNumber = (input) => {
  const reg = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

  return reg.test(input);
};

export const validatePhoneNumberWithZero = (input) => {
  const regex = /^(0)[0-9]{9}$/;
  return regex.test(input.toString());
};

export const validateOnlyNumbers = (input) => {
  const reg = /^\d+$/;

  return reg.test(input);
};
