/** @format */

import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Header from 'components/Layout/Onboarding/Header';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowRight } from '@fortawesome/free-solid-svg-icons';

import { Link, Redirect } from 'react-router-dom';
import { useLocalStorage } from 'utils/hooks';
import getUrlParams from 'utils/getUrlParams';

export default function Welcome(props) {
  const text = props.t('lang_onboarding:welcome');
  const { header_title, buttons } = text;

  const [getBrokerId, setBrokerId] = useLocalStorage('brokerId', '');

  useEffect(() => {
    const { brokerId } = getUrlParams(['brokerId']);
    setBrokerId(brokerId);
  }, []);

  if (getBrokerId == undefined) {
    return <Redirect to={'/'} />;
  } else {
    return (
      <Container
        fluid
        className='onboardingwelcome-main-container onboarding-main-container-spacing'>
        <Header header_title={header_title} />
        <Row>
          <Col xl={5}></Col>
          <Col xl={2}>
            {buttons.map(({ btnText, url }, index) => (
              <Link to={url} key={index}>
                <Button
                  className={`btn ${
                    index === 0 ? 'btn-pink' : 'btn-white'
                  } onboardingwelcome-buttons-fullwidth ${
                    index === 1 && 'onboardingwelcome-buttons-top-spacing'
                  }`}>
                  {btnText}
                  <FontAwesomeIcon icon={faLongArrowRight} size='lg' />
                </Button>
              </Link>
            ))}
          </Col>
          <Col xl={5}></Col>
        </Row>
      </Container>
    );
  }
}
