/** @format */

import React from 'react';

export default function OnboardingHeader(props) {
  const { heading, step_description, note_heading, note_description } = props;
  return (
    <>
      <h4 className='onboardingsteps-heading'>{heading}</h4>
      <p className='text2r'>{step_description}</p>

      <div className='onboardingsteps-note'>
        <p className='text3m onboardingsteps-note-heading'>{note_heading}</p>
        <p className='text2r'>{note_description}</p>
      </div>
    </>
  );
}
