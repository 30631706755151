/** @format */

import * as actionTypes from 'stateContainer/action-types';

export const updateShowStepperMobile = (payload) => ({
  type: actionTypes.SHOW_STEPPER_MOBILE,
  payload
});

export const saveProfilePicture = (payload) => ({
  type: actionTypes.SAVE_PROFILE_PICTURE,
  payload
});

export const fetchBrokerProfile = (payload) => ({
  type: actionTypes.BROKER_PROFILE_REQUESTED,
  payload
});

export const fetchBrokerProfileBySlug = (payload) => ({
  type: actionTypes.BROKER_PROFILE_BY_SLUG_REQUESTED,
  payload
});

export const fetchBrokerObjects = (payload) => ({
  type: actionTypes.BROKER_OBJECTS_REQUESTED,
  payload
});
export const fetchBrokerObjectsBySlug = (payload) => ({
  type: actionTypes.BROKER_OBJECTS_BY_SLUG_REQUESTED,
  payload
});

export const fetchBrokerObject = (payload) => ({
  type: actionTypes.BROKER_OBJECT_REQUESTED,
  payload
});

export const fetchMoreBrokerObjects = (payload) => ({
  type: actionTypes.MORE_BROKER_OBJECTS_REQUESTED,
  payload
});
export const fetchMoreBrokerObjectsBySlug = (payload) => ({
  type: actionTypes.MORE_BROKER_OBJECTS_BY_SLUG_REQUESTED,
  payload
});

export const fetchViewingTimeSlots = (payload) => ({
  type: actionTypes.VIEWING_TIME_SLOTS_REQUESTED,
  payload
});
