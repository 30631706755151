/** @format */

import React from 'react';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'react-bootstrap';

export default function OnboardingFooter({
  buttons,
  btnPrevFunction,
  btnNextFunction,
  extraSpacing,
  loading,
  finalStepBtnText
}) {
  return (
    <div
      className={`onboardingstep-button-container ${
        extraSpacing && 'onboardingstep-button-container-top-spacing'
      }`}>
      {buttons.map(({ label }, index) => (
        <Button
          disabled={loading}
          key={index}
          onClick={
            index === 0 ? () => btnPrevFunction() : () => btnNextFunction()
          }
          className={`btn ${
            index === 0
              ? 'btn-white'
              : 'btn-pink onboardingsignup-button-spacing'
          }`}>
          {index === 0 && <FontAwesomeIcon icon={faLongArrowLeft} size='lg' />}
          {index !== 0 && loading && (
            <Spinner animation='border' role='status' />
          )}
          {index === 1 && finalStepBtnText ? (
            <span>{finalStepBtnText}</span>
          ) : (
            <span>{label}</span>
          )}
        </Button>
      ))}
    </div>
  );
}
