/** @format */

import React from 'react';
import { useDropzone } from 'react-dropzone';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpFromBracket } from '@fortawesome/free-solid-svg-icons';

export default function Uploader({
  uploadDescription,
  files,
  setFiles,
  editDescription
}) {
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': []
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    }
  });

  const thumbs = files.map((file) => (
    <div className='onboarding-dropzone-thumb' key={file.name}>
      <div className='onboarding-dropzone-thumb-inner'>
        <img src={file.preview} className='onboarding-dropzone-thumb-img' />
      </div>
    </div>
  ));

  return (
    <section>
      <div
        {...getRootProps({
          className: `${
            files.length > 0 && 'onboarding-dropzone-full'
          } onboarding-dropzone`
        })}>
        <div className='onboarding-dropzone-thumbscontainer'>{thumbs}</div>

        <input {...getInputProps()} />
        <p
          className={`text2r onboarding-dropzone-body ${
            files.length && 'onboarding-dropzone-remove-mb'
          }`}>
          {files.length > 0 ? (
            editDescription
          ) : (
            <>
              <FontAwesomeIcon
                icon={faArrowUpFromBracket}
                size='lg'
                className='onboarding-dropzone-icon'
              />
              <span>{uploadDescription}</span>
            </>
          )}
        </p>
      </div>
    </section>
  );
}
