/** @format */

import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchBrokerObjectsService } from 'services/apiService';
import * as actionTypes from 'stateContainer/action-types';

function* workerSaga({ payload }) {
  try {
    const response = yield call(fetchBrokerObjectsService, payload);
    if (response.data.status == 'success') {
      yield put({
        type: actionTypes.MORE_BROKER_OBJECTS_SUCCESS,
        payload: response.data
      });
    } else {
      throw new Error('Fetch more broker objects failed');
    }
  } catch (error) {
    yield put({ type: actionTypes.MORE_BROKER_OBJECTS_ERROR, error });
  }
}

export function* moreBrokerObjectsSaga() {
  yield takeLatest(actionTypes.MORE_BROKER_OBJECTS_REQUESTED, workerSaga);
}
