/** @format */

import React, { useState } from 'react';

import Uploader from 'components/Uploader';

import Layout from 'components/Layout/Onboarding/Layout.js';
import OnboardingFooter from 'components/Layout/Onboarding/Footer';

import { history } from 'stateContainer/history';
import AlertBox from 'components/Alert';
import { AiOutlineExclamationCircle } from 'react-icons/ai';

import { useDispatch, useSelector } from 'react-redux';

import { saveProfilePicture } from 'stateContainer/actions';

export default function ProfilePicture({ isMobileView, ...props }) {
  const {
    onboarding: { profilePicture }
  } = useSelector((state) => state);

  const [files, setFiles] = useState(profilePicture ?? []);

  const dispatch = useDispatch();

  const {
    heading,
    step_description,
    note_heading,
    note_description,
    buttons,
    subheading,
    upload_description,
    edit_description,
    pageId
  } = props.t('lang_onboarding:profilepicture');
  const errorMessage = props.t('lang_onboarding:formErrorMessage');
  const nextPageUrl = buttons[1].url;

  const [formError, setFormError] = useState(false);

  const stepperText = props.t('lang_onboarding:stepper');

  function handleSubmit() {
    if (files.length === 0) {
      setFormError(true);
      return;
    }
    dispatch(saveProfilePicture(files));
    history.push(nextPageUrl);
  }

  return (
    <Layout
      isMobileView={isMobileView}
      stepperText={stepperText}
      pageId={pageId}
      heading={heading}
      step_description={step_description}
      note_heading={note_heading}
      note_description={note_description}>
      <div>
        <p className='text3m'>{subheading}</p>
        {formError && (
          <AlertBox
            type='danger'
            text={errorMessage}
            icon={<AiOutlineExclamationCircle />}
            iconConfig={{ size: '18px' }}
          />
        )}
        <div className='onboarding-upload-container'>
          <Uploader
            uploadDescription={upload_description}
            editDescription={edit_description}
            files={files}
            setFiles={setFiles}
          />
        </div>
      </div>

      <OnboardingFooter
        buttons={buttons}
        btnPrevFunction={() => history.push(buttons[0].url)}
        btnNextFunction={() => handleSubmit()}
      />
    </Layout>
  );
}
