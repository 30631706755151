/** @format */

import app from 'config/app';
import constants from 'config/constants';
import i18nConfig from 'config/i18nConfig';

const config = {
  app,
  constants,
  i18nConfig
};

export default config;
