/** @format */

import axios from 'axios';

export const formikHelper = (
  formik,
  initialValues,
  setFormSubmitted,
  setFormError,
  setFormSuccess,
  urlParam,
  validationCondition,
  setFormErrorMessage,
  additionalValidationRules
) => {
  return formik({
    initialValues: initialValues,
    onSubmit: (values, { resetForm }) => {
      setFormError(false);
      setFormSuccess(false);
      setFormSubmitted(true);
      if (
        validationCondition.every((key) => {
          if (additionalValidationRules && additionalValidationRules.length) {
            const additionalRulesExist = additionalValidationRules.find(
              (additionalValidationRule) => {
                return additionalValidationRule.key === key;
              }
            );

            if (additionalRulesExist) {
              return additionalRulesExist.rule(values[key]);
            }
          }

          return values[key];
        })
      ) {
        setFormError(false);
        axios
          .post(
            `${process.env.REACT_APP_API_GATEWAY_URL}/${urlParam}`,
            values,
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          )
          .then(() => {
            setFormSuccess(true);
            resetForm();
          })
          .catch((error) => {
            setFormError(true);
            setFormErrorMessage && setFormErrorMessage('');

            if (error.response && error.response.data) {
              const errorResponseData = error.response.data;
              if (
                errorResponseData.error &&
                errorResponseData.error.type === 'Validation'
              ) {
                let validationErrors = '';

                errorResponseData.error.validationErrors.forEach(
                  (validationError) => {
                    validationErrors += `${validationError.message}\n`;
                  }
                );

                setFormErrorMessage && setFormErrorMessage(validationErrors);
              }
            }
          })
          .finally(() => {
            setFormSubmitted(false);
            window.scrollTo({ top: 160, left: 0, behavior: 'smooth' });
          });
      } else {
        setFormError(true);
        setFormSubmitted(false);
        window.scrollTo({ top: 160, left: 0, behavior: 'smooth' });
      }
    }
  });
};
