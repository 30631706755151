/** @format */
/**
 * Extract specified paramaters from url query strings
 * Params: [Object]
 * Usage: getUrlParams(['acr_values', 'id_token'])
 */

const getUrlParams = (arr_KeysToExtract) => {
  const params = {};
  if (arr_KeysToExtract.length === 0) return; // url_parameters_not_specified

  const { search } = window.location;
  arr_KeysToExtract.forEach((element) => {
    const extract = search.match(`${element}=([^&]*)`);
    if (!extract) return; // url_parameter_not_found

    params[element] = extract[1];
  });
  return params;
};

export default getUrlParams;
