/** @format */

import React from 'react';
import StickyBox from 'react-sticky-box';
import WhatWeOffer from 'views/Registration/HomePage/WhatWeOffer';

export default function StickyAnimations(props) {
  return (
    <>
      <div className='sticky-animations-container'>
        <StickyBox className='sticky-sidebar'>
          <div className='image-homepage-2' />
        </StickyBox>
        <div className='sticky-content'>
          <WhatWeOffer {...props} />
        </div>
      </div>

      <div className='mobile-no-animations-container'>
        <WhatWeOffer {...props} />
      </div>
    </>
  );
}
