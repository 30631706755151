/** @format */

import React from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from 'react-google-maps';

const MapComponent = withScriptjs(
  withGoogleMap(({ latitude, longitude }) => (
    <GoogleMap
      defaultZoom={8}
      defaultCenter={{ lat: longitude, lng: latitude }}>
      <Marker position={{ lat: longitude, lng: latitude }} />
    </GoogleMap>
  ))
);

export default MapComponent;
