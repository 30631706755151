/** @format */

import React from 'react';
import MobileStepper from '../MobileStepper';
import StepperBar from '../StepperBar';

export default function NavBar(props) {
  const { isMobileView, stepperText, pageId } = props;

  return (
    <>
      {!isMobileView ? (
        <StepperBar stepperText={stepperText} pageId={pageId} showImage />
      ) : (
        <MobileStepper stepperText={stepperText} pageId={pageId} />
      )}
    </>
  );
}
