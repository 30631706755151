/** @format */

import React from 'react';
import PostBlock from 'components/PostBlock';

export default function WhatWeDo(props) {
  const { tprop } = props;
  return (
    <div className='what-we-do-container'>
      <PostBlock
        buttons={tprop.buttons}
        heading={tprop.heading}
        description={tprop.description}
        imageClassNames={'image-homepage image-homepage-3'}
      />
    </div>
  );
}
