/** @format */

import React from 'react';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import StickyBox from 'react-sticky-box';

export default function ObjectListingHeader(props) {
  const {
    estate,
    title,
    buttonText,
    priceText,
    handleClick,
    startingPrice,
    finalPrice,
    biddingStarted,
    isBidding,
    isFinalPrice,
    finalPriceText,
    priceTextLabel
  } = props;

  function handleButtonClick(item) {
    return handleClick(item.text);
  }

  function getPriceText() {
    if (isFinalPrice) {
      return `${finalPrice} ${text.sek.label} ${finalPriceText}`;
    } else if (isBidding) {
      return biddingStarted;
    } else {
      return startingPrice;
    }
  }

  const { t } = useTranslation();

  const text = t('lang_common:object_listing:basicInfoObj');

  return (
    <>
      <div className='object-listing-header'>
        <div className='object-listing-header-left'>
          <div className='object-listing-header-top'>
            <h3>{title}</h3>
          </div>
          <div className='object-listing-header-bottom'>
            <div className='object-listing-header-icon icon-location'></div>
            <div className='object-listing-header-address text2r'>
              {estate?.baseInformation?.address?.streetAddress},{' '}
              {estate?.baseInformation?.address?.municipality}
            </div>
          </div>
        </div>
      </div>
      <StickyBox offsetTop={0} offsetBottom={20} className='sticky-box'>
        <div className='property-header-container'>
          <div className='property-header'>
            {buttonText &&
              buttonText.map((item) => (
                <Button
                  className={`btn btn-white object-listing-button-item`}
                  key={item.text}
                  onClick={() => handleButtonClick(item)}>
                  {item.text}
                </Button>
              ))}
          </div>
          <div className='object-listing-header-right'>
            <div className='object-listing-header-top'>
              <h4 className='object-listing-header-price'>
                {`${priceTextLabel}: ${text.sek.label} ${priceText}`}
              </h4>
            </div>
            <div className='object-listing-header-bottom'>
              <div className='object-listing-header-price'>
                {getPriceText()}
              </div>
            </div>
          </div>
        </div>
      </StickyBox>
    </>
  );
}
