/** @format */

import React from 'react';
import Button from 'react-bootstrap/Button';

export default function ContactSellerButton({ onClickHandler }) {
  return (
    <div className='broker-contact-header-button-wrapper'>
      <Button
        onClick={onClickHandler}
        className={`btn btn-white btn-primary broker-contact-header-button`}>
        Sälja | Köpa | Fri värdering
      </Button>
    </div>
  );
}
