/** @format */

import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowRight } from '@fortawesome/free-solid-svg-icons';

export default function CustomButton(props) {
  const {
    url,
    type,
    text = '', // string
    icon = false, // boolean
    isBgPink = false, // boolean -> pink if true, white if false
    className = '' // string -> custom classNames
  } = props;

  const buttonBgColor = isBgPink ? 'pink' : 'white';

  const buttonType = type ? type : 'button';

  return (
    <>
      {url ? (
        <Link to={url}>
          <Button
            type={buttonType}
            className={`btn btn-${buttonBgColor}  ${className} `}>
            {text}
            {icon && <FontAwesomeIcon icon={faLongArrowRight} size='lg' />}
          </Button>
        </Link>
      ) : (
        <Button
          type={buttonType}
          className={`btn btn-${buttonBgColor}  ${className} `}>
          {text}
          {icon && <FontAwesomeIcon icon={faLongArrowRight} size='lg' />}
        </Button>
      )}
    </>
  );
}
