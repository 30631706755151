/** @format */

import * as actionTypes from 'stateContainer/action-types';

const initialState = {
  data: [],
  isFetching: false,
  isFetchingMore: false,
  isError: false,
  page: 1,
  totalPages: 1
};

export const brokerObjectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.BROKER_OBJECTS_REQUESTED:
    case actionTypes.BROKER_OBJECTS_BY_SLUG_REQUESTED:
      return { ...state, isFetching: true };

    case actionTypes.BROKER_OBJECTS_SUCCESS:
    case actionTypes.BROKER_OBJECTS_BY_SLUG_SUCCESS:
      return {
        ...state,
        data: action.payload.data.objects,
        isFetching: false,
        page: action.payload.paging.page,
        totalPages: action.payload.paging.totalPages
      };

    case actionTypes.BROKER_OBJECTS_ERROR:
    case actionTypes.BROKER_OBJECTS_BY_SLUG_ERROR:
      return { ...state, isFetching: false, isError: true };

    case actionTypes.MORE_BROKER_OBJECTS_REQUESTED:
    case actionTypes.MORE_BROKER_OBJECTS_BY_SLUG_REQUESTED:
      return { ...state, isFetchingMore: true };

    case actionTypes.MORE_BROKER_OBJECTS_SUCCESS:
    case actionTypes.MORE_BROKER_OBJECTS_BY_SLUG_SUCCESS:
      return {
        ...state,
        data: [...state.data, ...action.payload.data.objects],
        page: action.payload.paging.page,
        totalPages: action.payload.paging.totalPages,
        isFetchingMore: false
      };

    case actionTypes.MORE_BROKER_OBJECTS_ERROR:
    case actionTypes.MORE_BROKER_OBJECTS_BY_SLUG_ERROR:
      return {
        ...state,
        isError: true,
        isFetchingMore: false
      };

    default:
      return state;
  }
};
