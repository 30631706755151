/** @format */

import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useFormik } from 'formik';
import ButtonSyncLoader from 'components/ButtonSyncLoader';
import { Spinner } from 'react-bootstrap';
import moment from 'moment/moment';
import { useDispatch, useSelector } from 'react-redux';
import { fetchViewingTimeSlots } from 'stateContainer/actions';
import { useTranslation } from 'react-i18next';
import { formikHelper } from 'utils/formikHelper';
import Input from 'components/Forms/Input';
import AddressBlock from 'components/Forms/AddressBlock';
import NameBlock from 'components/Forms/NameBlock';
import AlertBlock from 'components/Forms/AlertBlock';
import ModalWrapper from '..';

export default function BookAViewing(props) {
  const { t } = useTranslation();
  const text = t('lang_common:object_listing:modals:forms:bookAViewingForm');

  const [formError, setFormError] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);
  const { viewings, customerId, objectId, open, openBookAViewing, brokerId } =
    props;

  const [selectedViewing, setSelectedViewing] = useState('');

  const formikHook = useFormik;

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    contactNo: '',
    streetAddress: '',
    zipCode: '',
    city: '',
    viewingId: '',
    timeSlotId: '',
    customerId: customerId,
    userId: brokerId
  };

  const formik = formikHelper(
    formikHook,
    initialValues,
    setFormSubmitted,
    setFormError,
    setFormSuccess,
    'bookAViewing',
    [
      'firstName',
      'lastName',
      'email',
      'contactNo',
      'streetAddress',
      'zipCode',
      'city',
      'viewingId',
      'timeSlotId'
    ],
    setFormErrorMessage
  );

  const setViewing = (event) => {
    setSelectedViewing(event.target.value);
    formik.setFieldValue('viewingId', event.target.value);
  };

  const dispatch = useDispatch();

  const {
    viewingTimeSlots: { data: viewingTimeSlots, isFetching: isFetching }
  } = useSelector((state) => state);

  useEffect(() => {
    if (selectedViewing !== '') {
      dispatch(fetchViewingTimeSlots({ customerId, objectId }));
    } else {
      setTimeSlots([]);
    }
  }, [selectedViewing]);

  const [timeSlots, setTimeSlots] = useState([]);

  useEffect(() => {
    if (viewingTimeSlots && viewingTimeSlots.length) {
      let timeSlotsToBeUpdated = [];
      const filteredViewing = viewingTimeSlots.find((viewingTimeSlot) => {
        return viewingTimeSlot.id === selectedViewing;
      });
      if (filteredViewing) {
        filteredViewing.timeSlots.forEach((timeSlot) => {
          filteredViewing.participantPerTimeSlot
            ? filteredViewing.participantPerTimeSlot >
                timeSlot.participants.length &&
              timeSlotsToBeUpdated.push(timeSlot)
            : timeSlotsToBeUpdated.push(timeSlot);
        });
      }
      setTimeSlots(timeSlotsToBeUpdated);
    } else {
      setTimeSlots([]);
    }
  }, [viewingTimeSlots]);

  const closeModal = () => {
    openBookAViewing(false);
  };

  return (
    <>
      <ModalWrapper
        heading={text.heading?.label}
        description={text.description}
        openModal={closeModal}
        open={open}>
        <form onSubmit={formik.handleSubmit}>
          <AlertBlock
            formError={formError}
            formErrorMessage={
              formErrorMessage !== ''
                ? formErrorMessage
                : text.formErrorMessage?.validation
            }
            formSuccess={formSuccess}
            formSuccessMessage={text.formSuccessMessage}
          />
          <NameBlock
            formikValues={formik.values}
            formikHandleChange={formik.handleChange}
            text={text}
            formSubmitted={formSubmitted}
          />

          <Row>
            <Input
              identifier='email'
              type='email'
              value={formik.values.email}
              handleChange={formik.handleChange}
              label={text.email?.label}
              disabled={formSubmitted}
            />
            <Input
              identifier='contactNo'
              type='text'
              value={formik.values.contactNo}
              handleChange={formik.handleChange}
              label={text.contactNo?.label}
              disabled={formSubmitted}
            />
          </Row>
          <AddressBlock
            formikValues={formik.values}
            formikHandleChange={formik.handleChange}
            text={text}
            formSubmitted={formSubmitted}
          />
          <Row>
            <Col className='modal-field-container' sm={12} md={6}>
              <label htmlFor='viewingId' className='text3m required-label'>
                {text.viewingId?.label}
              </label>
              <select
                id='viewingId'
                name='viewingId'
                onChange={setViewing}
                value={formik.values.viewingId}
                className='modal-field-input modal-description'
                disabled={formSubmitted}>
                <option key={0} value={''}>
                  {text.viewingId?.default}
                </option>
                {viewings !== null &&
                  viewings.length &&
                  viewings.map((viewing) => {
                    if (
                      moment(viewing.startTime).isSameOrAfter(moment(), 'day')
                    ) {
                      return (
                        <option value={viewing.id} key={viewing.id}>
                          {moment(viewing.startTime).format('YYYY-MM-DD')}
                        </option>
                      );
                    }
                  })}
              </select>
            </Col>
            <Col className='modal-field-container' sm={12} md={6}>
              <label htmlFor='timeSlotId' className='text3m required-label'>
                {text.timeSlotId?.label}
              </label>
              {isFetching ? (
                <Spinner
                  className='modal-spinner'
                  as='span'
                  animation='border'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
              ) : (
                <select
                  id='timeSlotId'
                  name='timeSlotId'
                  onChange={formik.handleChange}
                  value={formik.values.timeSlotId}
                  className='modal-field-input modal-description'
                  disabled={formSubmitted || timeSlots.length <= 0}>
                  <option key={1} value={''}>
                    {timeSlots.length > 0
                      ? text.timeSlotId?.default
                      : text.timeSlotIdDisabled?.default}
                  </option>
                  {timeSlots &&
                    timeSlots.length &&
                    timeSlots.map((timeSlot) => {
                      return (
                        <option key={timeSlot.id} value={timeSlot.id}>
                          {moment(timeSlot.startsAt).format('HH:mm')} -{' '}
                          {moment(timeSlot.endsAt).format('HH:mm')}
                        </option>
                      );
                    })}
                </select>
              )}
            </Col>
          </Row>
          <div className='modal-button-container'>
            <Button
              className={'btn btn-pink mobile-modal-button-width'}
              type='submit'
              disabled={formSubmitted}>
              {formSubmitted ? <ButtonSyncLoader /> : text.submitButton?.label}
            </Button>
          </div>
        </form>
      </ModalWrapper>
    </>
  );
}
