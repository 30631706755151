/** @format */

import React from 'react';
import Header from 'components/Layout/Header';
import Footer from 'components/Layout/Footer';
import Container from 'react-bootstrap/Container';
import Intro from 'views/Registration/HomePage/Intro';
import Partners from 'views/Registration/HomePage/Partners';
import Benefits from 'views/Registration/HomePage/Benefits';
import WhatWeDo from 'views/Registration/HomePage/WhatWeDo';
import FeeCalculator from 'views/Registration/HomePage/FeeCalculator';
import StickyAnimations from 'views/Registration/HomePage/StickyAnimations';
import {
  LeadsGeneratorAccordion,
  LeadSources
} from 'views/Registration/HomePage/LeadsGenerator';

export default function HomePage(props) {
  const { tprop } = props;

  return (
    <>
      <div className='main-container homepage-view'>
        <Header {...props} />

        <Container fluid className='content-wrapper'>
          <LeadsGeneratorAccordion
            metadata={{
              source: LeadSources.FM_HOME
            }}
            {...props}
          />

          <Intro {...props} tprop={tprop.intro} />

          <Partners {...props} tprop={tprop.partners} />

          <StickyAnimations {...props} tprop={tprop.whatWeOffer} />

          <FeeCalculator {...props} tprop={tprop.feeCalculator} />

          <Benefits {...props} tprop={tprop.benefits} />

          <WhatWeDo {...props} tprop={tprop.whatWeDo} />
        </Container>

        <Footer {...props} />
      </div>
    </>
  );
}
