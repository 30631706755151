/** @format */

import React from 'react';
import PostBlock from 'components/PostBlock';

export default function WhatWeOffer(props) {
  const { tprop } = props;
  return (
    <div className='what-we-offer-container'>
      <h3>{tprop.heading.text}</h3>
      {tprop.content.map((item, key) => (
        <div key={key} className='post-wrapper'>
          <PostBlock
            key={key}
            buttons={item.buttons}
            subheading={item.subheading}
            description={item.description}
          />
          <div className='icon-mobile-container'>
            <div className={` icon-what-we-offer-${key + 1}`}></div>
          </div>
        </div>
      ))}
    </div>
  );
}
