/** @format */

import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import getUrlParams from 'utils/getUrlParams';
import Header from 'components/Layout/Header';
import Footer from 'components/Layout/Footer';
import Container from 'react-bootstrap/Container';
import { useSelector } from 'react-redux';

export default function ViewImages(props) {
  const { objectId } = getUrlParams(['objectId']);
  const goBackUrl = `${props.t(
    'lang_route:common_object_listing'
  )}?objectId=${objectId}`;
  const {
    brokerObject: { data }
  } = useSelector((state) => state);

  const imageList = (data && data.object && data.object.imageList) || undefined;

  return (
    <>
      {!imageList && <Redirect to={goBackUrl} />}
      <div className='main-container imagelist-view'>
        <Header flow={props.flow} {...props} />

        <Container fluid className='content-wrapper'>
          <Link to={goBackUrl}>
            <div className='icon-go-back'></div>
          </Link>
          <div className='imagelist-view-all-image-container'>
            <div className='imagelist-view-all-image-primary-image-container'>
              <Image
                rounded
                thumbnail={false}
                src={imageList[0]}
                className='image-primary-image'
              />
            </div>
            <div className='imagelist-view-all-image-secondary-image-container'>
              {imageList.map((item, key) => (
                <React.Fragment key={key}>
                  {key >= 1 && (
                    <Image
                      rounded
                      thumbnail={true}
                      src={item}
                      className='image-secondary-image'
                      id={key}
                    />
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </Container>

        <Footer {...props} />
      </div>

      <div className='main-container imagelist-view-mobile'>
        <Header flow={props.flow} {...props} />

        <Container fluid className='content-wrapper'></Container>

        <Footer {...props} />
      </div>
    </>
  );
}
