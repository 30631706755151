/** @format */

import * as actionTypes from 'stateContainer/action-types';

const initialState = {
  data: [],
  isFetching: false,
  isError: false
};

export const brokerObjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.BROKER_OBJECT_REQUESTED:
      return { ...state, data: [], isFetching: true };

    case actionTypes.BROKER_OBJECT_SUCCESS:
      return { ...state, data: action.payload, isFetching: false };

    case actionTypes.BROKER_OBJECT_ERROR:
      return { ...state, isFetching: false, isError: true };

    default:
      return state;
  }
};
