/** @format */

let appConfig = process.env.REACT_APP_CONFIG || {};

if (typeof JSON.parse(JSON.stringify(appConfig)) === 'string') {
  appConfig = JSON.parse(appConfig);
} else {
  appConfig = JSON.parse(JSON.stringify(appConfig));
}

const { ENVIRONMENT } = appConfig;

const config = {
  ENVIRONMENT,
  defaultPathName: '/',
  mobileScreenMaxWidth: 919,
  googleMapsApiKey: process.env.REACT_APP_GOOGLEMAP_API_KEY,

  /**
   * Base url for Fria maklare APi server
   */
  baseApiUrl: process.env.REACT_APP_API_GATEWAY_URL,
  brokerLoginUrl: process.env.REACT_APP_BROKER_LOGIN_URL
};

export default config;
