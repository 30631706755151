/** @format */

import { React } from 'react';
import { Col, Row } from 'react-bootstrap';
import Input from '../Input';

export default function AddressBlock(props) {
  const { formikValues, formikHandleChange, text, formSubmitted } = props;

  return (
    <>
      <Row>
        <Col className='modal-field-container' sm={12} md={6}>
          <label htmlFor='streetAddress' className='text3m required-label'>
            {text.address.label}
          </label>
        </Col>
      </Row>
      <Row>
        <Input
          identifier='streetAddress'
          type='text'
          value={formikValues.streetAddress}
          handleChange={formikHandleChange}
          label={text.streetAddress.label}
          disabled={formSubmitted}
          placeholderOnly={true}
        />
        <Input
          identifier='zipCode'
          type='text'
          value={formikValues.zipCode}
          handleChange={formikHandleChange}
          label={text.zipCode.label}
          placeholderOnly={true}
          disabled={formSubmitted}
        />
      </Row>
      <Row>
        <Input
          identifier='city'
          type='text'
          value={formikValues.city}
          handleChange={formikHandleChange}
          label={text.city.label}
          placeholderOnly={true}
          disabled={formSubmitted}
        />
        <Col className='modal-field-container'></Col>
      </Row>
    </>
  );
}
