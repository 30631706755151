/** @format */

import LocizeBackend from 'i18next-locize-backend';
import Translations from 'assets/locale/translations';
import resourcesToBackend from 'i18next-resources-to-backend';

const VERSION = 'latest';
const CURRENT_LANGUAGE = 'sv-SE';
const FALLBACK_LANGUAGE = 'sv-SE';
const REFERENCE_LANGUAGE = 'sv-SE';
const DEFAULT_NAMESPACE = 'lang_registration';
const NAMESPACES_ARRAY = [
  'lang_route',
  'lang_footer',
  'lang_header',
  'lang_common',
  'lang_registration',
  'lang_onboarding'
];

export default {
  lng: CURRENT_LANGUAGE,
  debug: false,
  returnObjects: true,
  fallbackLng: FALLBACK_LANGUAGE,
  referenceLng: REFERENCE_LANGUAGE,
  ns: NAMESPACES_ARRAY,
  defaultNS: DEFAULT_NAMESPACE,
  interpolation: {
    escapeValue: false,
    joinArrays: true
  },
  backend: {
    backends: [LocizeBackend, resourcesToBackend(Translations)],
    backendOptions: [
      {
        projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID,
        apiKey: process.env.REACT_APP_LOCIZE_LOCIZE_API_KEY,
        version: VERSION,
        referenceLng: REFERENCE_LANGUAGE
      }
    ]
  },

  load: 'currentOnly',
  react: {
    useSuspense: false
  }
};
