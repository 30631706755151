/** @format */

export default {
  /**
   * application flows
   */
  flows: {
    registration: 'registration',
    onboarding: 'onboarding',
    common: 'common'
  },
  /**
   * flowOrigins to identify the source of the flow
   */
  flowOrigins: {
    registration: 'registration',
    onboarding: 'onboarding',
    common: 'common'
  },
  /**
   * views represent every single page in the application excluding modals
   */
  views: {
    page_not_found: 'page_not_found',
    homepage: 'homepage',
    article: 'article',
    article2: 'article2',
    register: 'register',
    login: 'login',
    faq: 'faq',
    about: 'about',
    contact: 'contact',
    broker_profile: 'broker_profile',
    object_listing: 'object_listing',
    object_view_images: 'object_view_images',
    welcome: 'welcome',
    fmhub: 'fmhub',
    agreement: 'agreement',
    signup: 'signup',
    contactinfo: 'contactinfo',
    billingaddress: 'billingaddress',
    activeareas: 'activeareas',
    profilepicture: 'profilepicture',
    presentation: 'presentation',
    usp: 'usp',
    yourservices: 'yourservices'
  },
  colors: {
    /**
     * this object would represent the classNames of colors instead of the actual color
     **/

    white: 'btn-white',
    pink: 'btn-pink'
  }
};
