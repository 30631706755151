/** @format */

import React, { useState } from 'react';
import Header from 'components/Layout/Header';
import Footer from 'components/Layout/Footer';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { registerUserApiService } from 'services/apiService';
import AlertBox from 'components/Alert';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ButtonSyncLoader from 'components/ButtonSyncLoader';
import { Link } from 'react-router-dom';

export default function Register(props) {
  const text = props.t('lang_registration:register');
  const [dataSubmitPendingState, setDataSubmitPendingState] = useState(false);
  const [dataSubmitSuccessState, setDataSubmitSuccessState] = useState(false);
  const [dataSubmitFailMessageState, setDataSubmitFailMessageState] =
    useState(null);

  const resetDataSubmitStates = () => {
    setDataSubmitSuccessState(false);
    setDataSubmitFailMessageState(null);
  };

  const formikValidationSchema = () =>
    Yup.object().shape({
      firstName: Yup.string()
        .trim()
        .required(
          props.t(
            'lang_registration:register.messages.validation.firstNameRequired'
          )
        ),
      lastName: Yup.string()
        .trim()
        .required(
          props.t(
            'lang_registration:register.messages.validation.lastNameRequired'
          )
        ),
      email: Yup.string()
        .email(
          props.t('lang_registration:register.messages.validation.emailInvalid')
        )
        .required(
          props.t(
            'lang_registration:register.messages.validation.emailRequired'
          )
        )
    });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: ''
    },
    onSubmit: (values, { resetForm }) => {
      resetDataSubmitStates();
      if (values.firstName && values.lastName && values.email) {
        setDataSubmitFailMessageState(null);
        setDataSubmitPendingState(true);
        registerUserApiService(values.firstName, values.lastName, values.email)
          .then(() => {
            resetForm();
            setDataSubmitSuccessState(true);
          })
          .catch((error) => {
            const errorMessageCode = error.response.data.languageErrorCode
              ? error.response.data.languageErrorCode
              : 'RB_GeneralError';
            setDataSubmitFailMessageState(
              props.t(
                `lang_registration:register.messages.apiErrorMessages.${errorMessageCode}`
              )
            );
          })
          .finally(() => {
            setDataSubmitPendingState(false);
          });
      } else {
        setDataSubmitPendingState(false);
      }
    },
    validationSchema: formikValidationSchema,
    validateOnChange: false,
    validateOnBlur: false
  });

  return (
    <>
      <div className='main-container'>
        <Header {...props} />
        <div className='registerpage-inner-container'>
          <Container>
            <h3>{text.heading}</h3>
            <Row>
              <Col md={8}>
                <p className='text2r registerpage-description'>
                  {text.description}
                </p>
                <form onSubmit={formik.handleSubmit}>
                  {dataSubmitSuccessState && (
                    <Row>
                      <Col>
                        <AlertBox
                          type='success'
                          text={props.t(
                            'lang_registration:register.messages.apiSuccessMessages.RB_Success'
                          )}
                          icon={<AiOutlineExclamationCircle />}
                          iconConfig={{ size: '18px' }}
                        />
                      </Col>
                    </Row>
                  )}
                  {dataSubmitFailMessageState && (
                    <Row>
                      <Col>
                        <AlertBox
                          type='danger'
                          text={dataSubmitFailMessageState}
                          icon={<AiOutlineExclamationCircle />}
                          iconConfig={{ size: '18px' }}
                        />
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col className='registerpage-field-container'>
                      <label htmlFor='firstName' className='text3m'>
                        {text.form_label_1}{' '}
                        <span style={{ color: 'red' }}>*</span>
                      </label>
                      <input
                        id='firstName'
                        name='firstName'
                        type='text'
                        placeholder={text.placeholder}
                        className='registerpage-field-input'
                        onChange={formik.handleChange}
                        value={formik.values.firstName}
                      />
                      <label className='registerpage-description registerpage-validation-error-label'>
                        {formik.errors.firstName}
                      </label>
                    </Col>
                    <Col className='registerpage-field-container'>
                      <label htmlFor='lastName' className='text3m'>
                        {text.form_label_2}{' '}
                        <span style={{ color: 'red' }}>*</span>
                      </label>
                      <input
                        id='lastName'
                        name='lastName'
                        type='text'
                        placeholder={text.placeholder}
                        className='registerpage-field-input'
                        onChange={formik.handleChange}
                        value={formik.values.lastName}
                      />
                      <label className='registerpage-description registerpage-validation-error-label'>
                        {formik.errors.lastName != undefined &&
                          formik.errors.lastName}
                      </label>
                    </Col>
                  </Row>

                  <Row>
                    <Col className='registerpage-field-container'>
                      <label htmlFor='email' className='text3m'>
                        {text.form_label_3}{' '}
                        <span style={{ color: 'red' }}>*</span>
                      </label>
                      <input
                        id='email'
                        name='email'
                        type='text'
                        placeholder={text.placeholder}
                        className='registerpage-field-input'
                        onChange={formik.handleChange}
                        value={formik.values.email}
                      />
                      <label className='registerpage-description registerpage-validation-error-label'>
                        {formik.errors.email != undefined &&
                          formik.errors.email}
                      </label>
                    </Col>
                  </Row>
                  <div className='registerpage-button-container'>
                    <Link to={props.t(`lang_route:registration_article`)}>
                      <Button
                        className={
                          'btn btn-white registerpage-button-spacing mobile-registerpage-button-width'
                        }>
                        {props.tprop.buttons[0].label}
                      </Button>
                    </Link>
                    <Button
                      className={
                        'btn btn-pink mobile-registerpage-button-width'
                      }
                      type='submit'
                      disabled={dataSubmitPendingState}>
                      {dataSubmitPendingState ? (
                        <ButtonSyncLoader />
                      ) : (
                        props.tprop.buttons[1].label
                      )}
                    </Button>
                  </div>
                </form>
              </Col>
              <Col
                md={{ span: 3, offset: 1 }}
                xs={0}
                className='mobile-loginpage-image'>
                <img
                  src='images/image-registration-page.png'
                  className='registerpage-image-1'
                  alt='registration image'
                />
              </Col>
            </Row>
          </Container>
        </div>

        <Footer {...props} />
      </div>
    </>
  );
}
