/** @format */

/**
 *
 * Calculate final result after deductions
 */

export const calculateFinalResult = (value) => {
  const vatDeduction = calculationUtils.deductVat(value);

  const friaMaklareFees = calculationUtils.deductFriaMaklareFees(vatDeduction);

  const employerContribution =
    calculationUtils.deductEmployerContribution(friaMaklareFees);

  const incomeTaxDeduction =
    calculationUtils.deductIncomeTax(employerContribution);

  return Math.round(incomeTaxDeduction);
};

export const calculationUtils = {
  /**
   *
   * Deduct 20% VAT
   */
  deductVat: (value) => {
    return value - (value / 100) * 20;
  },
  /**
   *
   * Deduct 15% fees for Fria Maklare
   */
  deductFriaMaklareFees: (value) => {
    return value - (value / 100) * 15;
  },
  /**
   *
   * Deduct 31.42% employer contribution
   */
  deductEmployerContribution: (value) => {
    return value / 1.3142;
  },
  /**
   *
   * Deduct 30% income tax
   */
  deductIncomeTax: (value) => {
    return value - (value / 100) * 30;
  }
};
