/** @format */

import React, { useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Header from 'components/Layout/Onboarding/Header';
import Form from 'react-bootstrap/Form';

import Row from 'react-bootstrap/Row';
import { history } from 'stateContainer/history';

export default function Agreement(props) {
  const text = props.t('lang_onboarding:agreement');
  const { header_title } = text;

  const [isChecked, setIsChecked] = useState(false);

  return (
    <Container
      fluid
      className='onboardingagreement-main-container onboarding-main-container-spacing'>
      <Header header_title={header_title} />
      <Container className='onboardingagreement-body'>
        <Row>
          {text.agreements.map(({ heading, content }, index) => (
            <div key={index}>
              <p className='text3m'>{heading}</p>
              <p className='text2r'>{content}</p>
            </div>
          ))}
        </Row>
      </Container>

      <div className='onboardingagreement-footer-agreement onboardingagreement-footer-body'>
        <Container>
          <div className='onboardingagreement-footer-body'>
            <Form.Check
              type={'checkbox'}
              checked={isChecked}
              id={`default-checkbox`}
              label={text.read_status}
              className='text3m onboardingagreement-footer-spacing'
              onChange={() => setIsChecked((currentState) => !currentState)}
            />

            <Button
              onClick={() => history.push(text.button.url)}
              className='btn btn-pink onboardingagreement onboardingagreement-footer-button'
              disabled={!isChecked}>
              {text.button.title}
            </Button>
          </div>
        </Container>
      </div>
    </Container>
  );
}
