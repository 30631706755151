/** @format */

import React from 'react';
import { Container } from 'react-bootstrap';
import NavBar from '../NavBar';
import OnboardingHeader from '../OnboardingHeader';

import { useSelector } from 'react-redux';

export default function Layout({
  isMobileView,
  stepperText,
  pageId,
  heading,
  step_description,
  note_heading,
  note_description,
  children
}) {
  const {
    stepper: { show }
  } = useSelector((state) => state);

  return (
    <Container fluid>
      <div
        className={`${
          isMobileView
            ? 'onboardingsteps-mobile-main-container'
            : 'onboardingsteps-main-container'
        } `}>
        <NavBar
          isMobileView={isMobileView}
          stepperText={stepperText}
          pageId={pageId}
        />
        <div
          className={`onboardingsteps-main-body ${
            show && 'onboardingsteps-main-hide-overflow'
          }`}>
          <OnboardingHeader
            heading={heading}
            step_description={step_description}
            note_heading={note_heading}
            note_description={note_description}
          />
          <div className='onboardingsteps-content-body'>{children}</div>
        </div>
      </div>
    </Container>
  );
}
