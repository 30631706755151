/** @format */

import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
    // You can also log error messages to an error reporting service here
  }
  render() {
    if (this.state.error) {
      // Render a custom fallback UI in case of errors
      return (
        <div>
          <h2>
            Message from ErrorBoundary: Something went wrong. Please check your
            console for logs
          </h2>
        </div>
      );
    }
    // Continue to render the child components if there was no error
    return this.props.children;
  }
}

export default ErrorBoundary;
