/** @format */

import React from 'react';
import { Link } from 'react-router-dom';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function NavigationLinks(props) {
  const { t } = props;

  const footerText = t('lang_footer:links');

  return (
    <>
      {footerText.map((item, key) =>
        item.props && item.props.style && item.props.style.length > 0 ? (
          <div key={key} className={`footer-link-item ${item.props.style}`}>
            <FontAwesomeIcon icon={faCircle} className='bullet-style' />
          </div>
        ) : (
          <div className='footer-link-item' key={key}>
            <Link to={item.props.url} className='text4'>
              {item.text}
            </Link>
          </div>
        )
      )}
    </>
  );
}
