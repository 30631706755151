/** @format */

import React, { useState } from 'react';

import Layout from 'components/Layout/Onboarding/Layout.js';
import OnboardingFooter from 'components/Layout/Onboarding/Footer';
import { Field, FieldArray, Form, Formik } from 'formik';
import { Row } from 'react-bootstrap';

import { history } from 'stateContainer/history';

import AlertBox from 'components/Alert';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { useLocalStorage } from 'utils/hooks';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';

export default function USP({ isMobileView, ...props }) {
  const {
    pageId,
    heading,
    step_description,
    note_heading,
    note_description,
    buttons,
    add_title,
    form_label,
    placeholder
  } = props.t('lang_onboarding:usp');
  const [formError, setFormError] = useState(false);

  const stepperText = props.t('lang_onboarding:stepper');
  const errorMessage = props.t('lang_onboarding:formErrorMessage');

  const nextPageUrl = buttons[1].url;

  const [brokerUsps, setBrokerUsps] = useLocalStorage('brokerUsps', ['']);

  return (
    <Layout
      isMobileView={isMobileView}
      stepperText={stepperText}
      pageId={pageId}
      heading={heading}
      step_description={step_description}
      note_heading={note_heading}
      note_description={note_description}>
      {formError && (
        <AlertBox
          type='danger'
          text={errorMessage}
          icon={<AiOutlineExclamationCircle />}
          iconConfig={{ size: '18px' }}
        />
      )}
      <Formik
        initialValues={{ brokerUsps: brokerUsps ?? [] }}
        onSubmit={(values) => {
          if (values.brokerUsps.length !== 0) {
            let isEmpty = false;

            values.brokerUsps.map((usp) => {
              if (!usp) isEmpty = true;
            });

            if (isEmpty) {
              setFormError(true);
              return;
            }

            setBrokerUsps(values.brokerUsps);
            setFormError(false);
            history.push(nextPageUrl);
          } else {
            setFormError(true);
          }
        }}
        render={({ values, submitForm }) => (
          <Form>
            <label htmlFor='firstName' className='text3m'>
              {form_label}
            </label>
            <FieldArray
              name='brokerUsps'
              render={(arrayHelpers) => (
                <div>
                  {values.brokerUsps &&
                    values.brokerUsps.map((_brokerUsp, index) => (
                      <div
                        key={index}
                        className='onboardingsteps-usp-column-container'>
                        <div>
                          <Field
                            name={`brokerUsps.${index}`}
                            placeholder={placeholder}
                            className={`onboardingsignup-field-input`}
                          />

                          <FontAwesomeIcon
                            icon={faCircleXmark}
                            size='lg'
                            className='onboarding-remove-icon-spacing '
                            onClick={() => arrayHelpers.remove(index)}
                          />
                        </div>
                      </div>
                    ))}

                  <Row>
                    <div
                      className='onboarding-addnew-row'
                      onClick={() => arrayHelpers.unshift('')}>
                      <img
                        src='../images/add_icon.svg'
                        alt='header image'
                        className='onboardingusp-icon-spacing'
                      />
                      <div className='text5m'>{add_title}</div>
                    </div>
                  </Row>
                </div>
              )}
            />

            <OnboardingFooter
              buttons={buttons}
              btnPrevFunction={() => history.push(buttons[0].url)}
              btnNextFunction={() => submitForm()}
              extraSpacing
            />
          </Form>
        )}
      />
    </Layout>
  );
}
