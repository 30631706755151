/** @format */

export const SHOW_STEPPER_MOBILE = 'SHOW_STEPPER_MOBILE';
export const SAVE_PROFILE_PICTURE = 'SAVE_PROFILE_PICTURE';

export const BROKER_PROFILE_REQUESTED = 'BROKER_PROFILE_REQUESTED';
export const BROKER_PROFILE_SUCCESS = 'BROKER_PROFILE_SUCCESS';
export const BROKER_PROFILE_ERROR = 'BROKER_PROFILE_ERROR';

export const BROKER_PROFILE_BY_SLUG_REQUESTED =
  'BROKER_PROFILE_BY_SLUG_REQUESTED';
export const BROKER_PROFILE_BY_SLUG_SUCCESS = 'BROKER_PROFILE_BY_SLUG_SUCCESS';
export const BROKER_PROFILE_BY_SLUG_ERROR = 'BROKER_PROFILE_BY_SLUG_ERROR';

export const BROKER_OBJECTS_REQUESTED = 'BROKER_OBJECTS_REQUESTED';
export const BROKER_OBJECTS_SUCCESS = 'BROKER_OBJECTS_SUCCESS';
export const BROKER_OBJECTS_ERROR = 'BROKER_OBJECTS_ERROR';

export const BROKER_OBJECTS_BY_SLUG_REQUESTED =
  'BROKER_OBJECTS_BY_SLUG_REQUESTED';
export const BROKER_OBJECTS_BY_SLUG_SUCCESS = 'BROKER_OBJECTS_BY_SLUG_SUCCESS';
export const BROKER_OBJECTS_BY_SLUG_ERROR = 'BROKER_OBJECTS_BY_SLUG_ERROR';

export const BROKER_OBJECT_REQUESTED = 'BROKER_OBJECT_REQUESTED';
export const BROKER_OBJECT_SUCCESS = 'BROKER_OBJECT_SUCCESS';
export const BROKER_OBJECT_ERROR = 'BROKER_OBJECT_ERROR';

export const MORE_BROKER_OBJECTS_REQUESTED = 'MORE_BROKER_OBJECTS_REQUESTED';
export const MORE_BROKER_OBJECTS_SUCCESS = 'MORE_BROKER_OBJECTS_SUCCESS';
export const MORE_BROKER_OBJECTS_ERROR = 'MORE_BROKER_OBJECTS_ERROR';

export const MORE_BROKER_OBJECTS_BY_SLUG_REQUESTED =
  'MORE_BROKER_OBJECTS_BY_SLUG_REQUESTED';
export const MORE_BROKER_OBJECTS_BY_SLUG_SUCCESS =
  'MORE_BROKER_OBJECTS_BY_SLUG_SUCCESS';
export const MORE_BROKER_OBJECTS_BY_SLUG_ERROR =
  'MORE_BROKER_OBJECTS_BY_SLUG_ERROR';

export const VIEWING_TIME_SLOTS_REQUESTED = 'VIEWING_TIME_SLOTS_REQUESTED';
export const VIEWING_TIME_SLOTS_SUCCESS = 'VIEWING_TIME_SLOTS_SUCCESS';
export const VIEWING_TIME_SLOTS_ERROR = 'VIEWING_TIME_SLOTS_ERROR';
