/** @format */

import en_GB from './en_GB';
import sv_SE from './sv_SE';

const Translations = {
  'en-GB': en_GB,
  'sv-SE': sv_SE
};

export default Translations;
