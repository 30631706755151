/** @format */

import React from 'react';

export default function BrokerServiceBox({ service, index }) {
  return (
    <p className='text4' key={index}>
      {service}
    </p>
  );
}
