/** @format */

import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import Layout from 'components/Layout/Onboarding/Layout.js';
import OnboardingFooter from 'components/Layout/Onboarding/Footer';
import { history } from 'stateContainer/history';

import { useFormik } from 'formik';
import { useLocalStorage } from 'utils/hooks';
import AlertBox from 'components/Alert';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { validatePhoneNumberWithZero } from 'utils/validations';

export default function ContactInfo({ isMobileView, ...props }) {
  const {
    pageId,
    heading,
    step_description,
    note_heading,
    note_description,
    buttons,
    form_label,
    placeholder,
    id
  } = props.t('lang_onboarding:contactinfo');
  const errorMessage = props.t('lang_onboarding:formErrorMessage');
  const formPhoneNumberErrorMessage = props.t(
    'lang_onboarding:formPhoneNumberErrorMessage'
  );

  const stepperText = props.t('lang_onboarding:stepper');
  const nextPageUrl = buttons[1].url;

  const [formError, setFormError] = useState('');

  const [phoneNumber, setPhoneNumber] = useLocalStorage('phoneNumber', '');

  const formik = useFormik({
    initialValues: {
      phoneNumber: phoneNumber ?? ''
    },
    onSubmit: (values) => {
      if (!values.phoneNumber) {
        setFormError(errorMessage);
        return;
      }

      if (!validatePhoneNumberWithZero(values.phoneNumber)) {
        setFormError(formPhoneNumberErrorMessage);
        return;
      }

      setPhoneNumber(values.phoneNumber);
      setFormError('');
      history.push(nextPageUrl);
    }
  });

  return (
    <Layout
      isMobileView={isMobileView}
      stepperText={stepperText}
      pageId={pageId}
      heading={heading}
      step_description={step_description}
      note_heading={note_heading}
      note_description={note_description}>
      <form>
        <Row>
          <Col md={6}>
            {formError.length > 0 && (
              <AlertBox
                type='danger'
                text={formError}
                icon={<AiOutlineExclamationCircle />}
                iconConfig={{ size: '18px' }}
              />
            )}
            <div className='onboardingsteps-column-container'>
              <label htmlFor='firstName' className='text3m'>
                {form_label}
              </label>
              <input
                id={id}
                name={id}
                type='tel'
                step={1}
                min={0}
                minLength={10}
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                placeholder={placeholder}
                className={`onboardingsignup-field-input telephone`}
              />
            </div>
          </Col>
          <Col md={6}></Col>
        </Row>
      </form>
      <OnboardingFooter
        buttons={buttons}
        btnPrevFunction={() => history.push(buttons[0].url)}
        btnNextFunction={() => formik.handleSubmit()}
      />
    </Layout>
  );
}
