/** @format */

import React from 'react';
import Alert from 'react-bootstrap/Alert';
import { IconContext } from 'react-icons';

export default function AlertBox(props) {
  return (
    <>
      <Alert key={props.type} variant={props.type}>
        {props.icon ? (
          <IconContext.Provider value={props.iconConfig || null}>
            {props.icon}
          </IconContext.Provider>
        ) : null}{' '}
        <span>{props.text}</span>
      </Alert>
    </>
  );
}
